import { Reducer } from "react";
import { DASHBOARD_FETCHED, Dashboard, DashboardState, ONBOARDED, PREFS_UPDATED, UPDATE_DASHBOARD, UPDATE_HAS_FETCHED_AGES, UPDATE_REFORME_DA, User } from "./types";
import { getLastPathDate } from "./utils";

const initialState: DashboardState = {
   // dashboard est undefined
   isOnboarded: false,
   vientDeFaireReformeDA: false,
   hasFetchedAges: false,
} as DashboardState;

export const DashboardReducer: Reducer<DashboardState, any> = (currentState = initialState, action): DashboardState => {
   switch (action.type) {
      case DASHBOARD_FETCHED:
         return {
            ...currentState,
            // TODO: Les dates arrivent au format string alors que c'est censé être de la Date|undefined
            // Ne devrait-on pas faire le parsing ici ?
            dashboard: action.payload,
         };
      case UPDATE_DASHBOARD:
         return {
            ...currentState,
            dashboard: action.payload,
         };
      case PREFS_UPDATED:
         return {
            ...currentState,
            dashboard: updateDashboard(currentState.dashboard, action.payload),
         };
      case ONBOARDED:
         return {
            ...currentState,
            isOnboarded: true,
         };
      case UPDATE_REFORME_DA:
         return {
            ...currentState,
            vientDeFaireReformeDA: action.payload,
         };
      case UPDATE_HAS_FETCHED_AGES:
            return {
               ...currentState,
               hasFetchedAges: action.payload,
            };
      default:
         return currentState;
   }
};

// Mise à jour des données en local sans refetch du Dashboard
const updateDashboard = (dashboard: Partial<Dashboard>, data: any): Partial<Dashboard> => {
   const currentUser: User = data as User;
   // Sera alimenté à la création pour éviter un appel en boucle
   if (!dashboard.creationDate) {
      return { ...dashboard, creationDate: new Date().toISOString() };
   }
   // Peut être mis à jour depuis l'écran dashboard => Mise à jour temps réel
   if (currentUser.monthlyIncomeGoal !== undefined && !dashboard.firstMonthlyIncomeGoalInputDate) {
      return updateLastPathDate({ ...dashboard, monthlyIncomeGoal: currentUser.monthlyIncomeGoal }, "firstMonthlyIncomeGoalInputDate");
   }
   if (currentUser.legalNoticesAcceptanceDate) {
      return { ...dashboard, legalNoticesAcceptanceDate: currentUser.legalNoticesAcceptanceDate.toISOString() };
   }
   if (!dashboard.firstSavingPathDate && currentUser.firstSavingPathDate) {
      return updateLastPathDate(dashboard, "firstSavingPathDate");
   }
   if (!dashboard.firstPatrimonyPathDate && currentUser.firstPatrimonyPathDate) {
      return updateLastPathDate(dashboard, "firstPatrimonyPathDate");
   }
   if (!dashboard.firstMyPlanDate && currentUser.firstMyPlanDate) {
      return updateLastPathDate(dashboard, "firstMyPlanDate");
   }
   if (!dashboard.firstMyServicesDate && currentUser.firstMyServicesDate) {
      return updateLastPathDate(dashboard, "firstMyServicesDate");
   }
   if (currentUser.disclaimerSeiAcceptanceDate) {
      return { ...dashboard, disclaimerSeiAcceptanceDate: currentUser.disclaimerSeiAcceptanceDate.toISOString() };
   }
   if (currentUser.firstSimulatorBeforeAfterDate) {
      return { ...dashboard, firstSimulatorBeforeAfterDate: currentUser.firstSimulatorBeforeAfterDate.toISOString() };
   }
   if (currentUser.firstDiaryConnectionDate) {
      return { ...dashboard, firstDiaryConnectionDate: currentUser.firstDiaryConnectionDate.toISOString() };
   }

   return {
      ...dashboard,
      ...{
         choosenAge: currentUser.choosenAge ?? dashboard.choosenAge,
         monthlyIncomeGoal: currentUser.monthlyIncomeGoal ?? dashboard.monthlyIncomeGoal,
      }
   };
};

function updateLastPathDate(dashboard: Partial<Dashboard>, newPathDateKey: string) {
   const lastDate = getLastPathDate(dashboard).date;
   // On ajoute 1s pour être le nouveau plus récent. La valeur sera mise à jour à la prochaine récupération du dashboard
   const newDate = new Date(lastDate.getTime() + 1000);

   return { ...dashboard, [newPathDateKey]: newDate.toISOString() };
}
