import { FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGDrawer } from "sg-drawer";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGScroll } from "sg-scroll";
import { SGBox, SGSpace } from "sg-space";
import { SGBlock, SGPrice, SGText, SGTitle } from "sg-typo";
import { postPostesDepenses } from "store/budgetRetraite/actions";
import { setObjectifRevenuBudgetOK } from "store/budgetRetraite/budgetRetraiteSlice";
import {
  InfoBudget,
  PosteDepense,
  PostesDepensesDTO,
} from "store/budgetRetraite/types";
import { majBornesPostes } from "store/budgetRetraite/utils";
import { updatePrefs } from "store/dashboard/actions";
import { User } from "store/dashboard/types";
import { useMontantsMax } from "website/components/hooks/dashboard/useMontantsMax";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { CadreSaisiePosteDepense } from "./CadreSaisiePosteDepense";

const findMontant = (
  postesDepenses: InfoBudget[],
  posteRecherche: PosteDepense
) =>
  postesDepenses.find(
    (posteDepense) => posteDepense.posteDepense === posteRecherche
  )?.montant ?? 0;

interface DrawerBudgetProps {
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
  infosBudget: InfoBudget[];
}

const DrawerBudget: FC<DrawerBudgetProps> = (props: DrawerBudgetProps) => {
  const { showDrawer, setShowDrawer, infosBudget } = props;

  const dispatch = useDispatch();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const { trackDrawer, trackClick } = useTracking();

  const { minObjectifInput, maxObjectifInput } = useMontantsMax();

  const [valeursSaisies, setValeursSaisies] =
    useState<InfoBudget[]>(infosBudget);

  const totalDepenses = useMemo(
    () =>
      valeursSaisies.reduce(
        (prev: number, poste: InfoBudget) => prev + (poste.montant || 0),
        0
      ),
    [valeursSaisies]
  );

  const erreurSaisieHorsBornes =
    totalDepenses < minObjectifInput || totalDepenses > maxObjectifInput;

  useEffect(() => {
    if (showDrawer) {
      trackDrawer("drawer-modifier-mes-depenses");
      // On fait une copie pour pouvoir modifier sans risque
      setValeursSaisies(infosBudget.map((poste) => ({
        ...poste,
      })));
    }
  }, [showDrawer, infosBudget]);

  const handleOnChangeMontant = (
    posteDepense: string,
    montant: number | undefined
  ) => {
    setValeursSaisies((anciennesValeurs: InfoBudget[]) => {
      const posteModifie = anciennesValeurs.find(
        (poste) => poste.posteDepense === posteDepense
      );
      if (posteModifie) {
        posteModifie.montant = montant;
      }
      const nouveauTotal = anciennesValeurs.reduce(
        (prev: number, poste) => prev + (poste.montant || 0),
        0
      );

      const nouvellesValeurs = anciennesValeurs.map((poste) => ({
        ...poste,
        pourcentage:
          nouveauTotal > 0
            ? Math.round((100 * (poste.montant || 0)) / nouveauTotal)
            : 0,
      }));
      if (nouveauTotal > 0) {
        majBornesPostes(nouvellesValeurs, nouveauTotal);
      }

      return nouvellesValeurs;
    });
  };

  const handleOnClickValider = () => {
    trackClick("drawer-modifier-mes-depenses::clic-sur-valider");
    const nouveauxPostesDepenses: PostesDepensesDTO = {
      montantLogement: findMontant(valeursSaisies, PosteDepense.logement),
      montantQuotidien: findMontant(valeursSaisies, PosteDepense.quotidien),
      montantTransports: findMontant(valeursSaisies, PosteDepense.transports),
      montantLoisirs: findMontant(valeursSaisies, PosteDepense.loisirs),
      montantSante: findMontant(valeursSaisies, PosteDepense.sante),
      montantImpots: findMontant(valeursSaisies, PosteDepense.impots),
    };
    dispatch(postPostesDepenses(nouveauxPostesDepenses));
    dispatch(setObjectifRevenuBudgetOK(totalDepenses));
    const user: User = {
      monthlyIncomeGoal: totalDepenses,
    };
    dispatch(updatePrefs(user));

    setShowDrawer(false);
  };

  const handleOnClickAnnuler = () => {
    trackClick("drawer-modifier-mes-depenses::clic-sur-annuler");
    setShowDrawer(false);
  };

  const renderPartieScrollable = () => (
    <SGSpace direction="vertical" disableautomargin>
      <SGGridRow key="postesDepenses" gutter={[16, 16]}>
        {valeursSaisies.map((posteDepense: InfoBudget) => (
          <SGGridCol key={posteDepense.posteDepense} span={12} md={6}>
            <CadreSaisiePosteDepense
              posteDepense={posteDepense}
              onChangeMontant={handleOnChangeMontant}
            />
          </SGGridCol>
        ))}
      </SGGridRow>
      <SGBox key="boutons" padding={{ top: "sm" }}>
        <SGButtonGroup align="center">
          <SGButton
            type="primary"
            onClick={handleOnClickValider}
            disabled={erreurSaisieHorsBornes}
          >
            <FormattedMessage id="monObjectif.drawerPersonnaliser.bouton.valider" />
          </SGButton>
          <SGButton type="secondary" onClick={handleOnClickAnnuler}>
            <FormattedMessage id="monObjectif.drawerPersonnaliser.bouton.annuler" />
          </SGButton>
        </SGButtonGroup>
      </SGBox>
    </SGSpace>
  );

  return (
    <SGDrawer
      placement={isPhone ? "bottom" : "right"}
      width={isPhone ? "sm" : "lg"}
      visible={showDrawer}
      onClose={handleOnClickAnnuler}
      title={
        <SGTitle key="titre" level={2} visuallevel={isPhone ? 2 : 4}>
          <FormattedMessage id="monObjectif.drawerPersonnaliser.titre" />
        </SGTitle>
      }
    >
      <SGSpace direction="vertical" disableautomargin>
        <SGBlock key="description">
          <SGBox alignchildren={["left", "center"]}>
            <>
              <SGBox inline padding={{ right: "sm" }}>
                <SGText weight="600">
                  <FormattedMessage id="monObjectif.drawerPersonnaliser.sousTitre" />
                </SGText>
              </SGBox>
              <SGText whitespace="nowrap">
                <SGPrice
                  size="xxl"
                  value={totalDepenses.toLocaleString("fr-FR")}
                  status={erreurSaisieHorsBornes ? "loss" : "default"}
                />
              </SGText>
            </>
          </SGBox>
          {erreurSaisieHorsBornes && (
            <SGText color="error">
              <FormattedMessage
                id="monObjectif.drawerPersonnaliser.erreurSaisie"
                values={{
                  borneMin: minObjectifInput,
                  borneMax: maxObjectifInput,
                }}
              />
            </SGText>
          )}
        </SGBlock>
        <SGMediaQuery key="scrollMobile" minwidth="xs" maxwidth="sm">
          <SGScroll key="scroll" bordered={false}>
            {renderPartieScrollable()}
          </SGScroll>
        </SGMediaQuery>
        <SGMediaQuery key="scrollDesktop" minwidth="md">
          {renderPartieScrollable()}
        </SGMediaQuery>
      </SGSpace>
    </SGDrawer>
  );
};

export { DrawerBudget };
