import { useMemo } from "react";
import { useSelector } from "react-redux";
import { computeAdditionalIncomes } from "store/assets/utils";
import { BudgetRetraiteState } from "store/budgetRetraite/types";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { State } from "../../../store/store";
import { useSelectedRetirementAge } from "./dashboard/useSelectedRetirementAge";
import { useAssets } from "./useAssets";

const useRevenuAFinancer = () => {
  const { objectifRevenu }: BudgetRetraiteState = useSelector<
    State,
    BudgetRetraiteState
  >((state) => state.budgetRetraite);

  const { selectedRetirementAge } = useSelectedRetirementAge();
  const { assets, hasFetchedAssets, assetIncomes, hasFetchedIncomes } =
    useAssets();
  const revenusPatrimoine = useMemo(() => {
    if (hasFetchedAssets && hasFetchedIncomes && selectedRetirementAge) {
      return roundDown10(
        computeAdditionalIncomes(assets, assetIncomes, selectedRetirementAge)
      );
    }

    return undefined;
  }, [
    hasFetchedAssets,
    assets,
    hasFetchedIncomes,
    assetIncomes,
    selectedRetirementAge,
  ]);

  const revenuAFinancer = useMemo(() => {
    if (
      objectifRevenu &&
      revenusPatrimoine !== undefined &&
      selectedRetirementAge
    ) {
      let total = objectifRevenu ?? 0;

      if (selectedRetirementAge.retirementMonthlyIncome >= 0) {
        total -= roundDown10(selectedRetirementAge.retirementMonthlyIncome);
      }
      const revenu = total - revenusPatrimoine;

      return revenu < 0 ? undefined : revenu;
    }

    return undefined;
  }, [objectifRevenu, revenusPatrimoine, selectedRetirementAge]);

  return { revenuAFinancer };
};

export { useRevenuAFinancer };
