import { InfoBudget } from "./types";

/**
 * Récupère les postes correspondant aux pourcentages et montants minimums et maximums.
 *
 * @param postesCalcules Postes à contrôler
 * @returns Les 4 postes aux bornes
 */
const determinerPostesAuxBornes = (postesCalcules: InfoBudget[]) => {
  // On évite de prendre un min à 0
  let pourcentMin =
    postesCalcules.find((poste) => poste.pourcentage > 0) || postesCalcules[0];
  let pourcentMax = postesCalcules[0];
  let montantMin =
    postesCalcules.find((poste) => (poste.montant ?? 0) > 0) ||
    postesCalcules[0];
  let montantMax = postesCalcules[0];

  postesCalcules.forEach((poste) => {
    if (poste.pourcentage > 0 && poste.pourcentage < pourcentMin.pourcentage) {
      pourcentMin = poste;
    } else if (poste.pourcentage > pourcentMax.pourcentage) {
      pourcentMax = poste;
    }
    if (
      (poste.montant ?? 0) > 0 &&
      (poste.montant ?? 0) < (montantMin.montant ?? 0)
    ) {
      montantMin = poste;
    } else if ((poste.montant ?? 0) > (montantMax.montant ?? 0)) {
      montantMax = poste;
    }
  });

  return { pourcentMin, pourcentMax, montantMin, montantMax };
};

/**
 * Ajouter plus ou moins la différence aux totaux attendus.
 * Modifie par adresse les postes de dépenses.
 *
 * @param postesCalcules Postes à contrôler
 * @param montantCible Montant total à cibler
 */
export const majBornesPostes = (
  postesCalcules: InfoBudget[],
  montantCible: number
) => {
  const { pourcentMin, pourcentMax, montantMin, montantMax } =
    determinerPostesAuxBornes(postesCalcules);

  const diffPourcentageTotal =
    100 -
    postesCalcules
      .map((poste) => poste.pourcentage)
      .reduce((prev, curr) => prev + curr, 0);
  const diffMontantTotal =
    montantCible -
    postesCalcules
      .map((poste) => poste.montant ?? 0)
      .reduce((prev, curr) => prev + curr, 0);

  // Pas assez, on ajoute au plus grand
  if (diffPourcentageTotal > 0) {
    pourcentMax.pourcentage += diffPourcentageTotal;
  } else if (diffPourcentageTotal < 0) {
    // Trop, on retire au plus petit
    pourcentMin.pourcentage += diffPourcentageTotal;
  }
  // Pas assez, on ajoute au plus grand
  if (diffMontantTotal > 0) {
    montantMax.montant = (montantMax.montant ?? 0) + diffMontantTotal;
  } else if (diffMontantTotal < 0) {
    // Trop, on retire au plus petit
    montantMin.montant = (montantMin.montant ?? 0) + diffMontantTotal;
  }
};
