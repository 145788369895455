import { FC, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGTile } from "sg-tile";
import { SGTitle } from "sg-typo";
import { BudgetRetraiteState } from "store/budgetRetraite/types";
import { State } from "store/store";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useBudgetRetraite } from "website/components/hooks/useBudgetRetraite";
import {
  LignesRevenus,
  RevenusElementListe,
} from "../Dashboard/Personnalisation/Revenus/LignesRevenus";
import { DrawerBudget } from "./DrawerBudget";

export const TuileBudgetRetraite: FC = () => {
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
  const intl = useIntl();

  const { trackClick } = useTracking();

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

  const { saisieEnErreur, objectifRevenu }: BudgetRetraiteState = useSelector<
    State,
    BudgetRetraiteState
  >((state) => state.budgetRetraite);

  const { infosBudget } = useBudgetRetraite();

  const dataSource: RevenusElementListe[] = useMemo(
    () =>
      infosBudget.map((infos) => ({
        tag: intl.formatMessage({
          id: `monObjectif.budget.tag.${infos.posteDepense}`,
        }),
        couleur: "45",
        suffixe: `${infos.pourcentage}%`,
        montant: infos.montant,
        showMontant: true,
        showSkeleton: false,
      })),
    [intl, infosBudget]
  );

  const handleOnClickPersonnaliser = () => {
    trackClick("clic-sur-personnaliser-mes-depenses");
    setDrawerVisible(true);
  };

  return (
    <SGTile clickable>
      <SGBox
        margin={isPhone ? undefined : { left: "xxl", right: "xxl" }}
        textalign="center"
      >
        <SGSpace direction="vertical" size="lg">
          <SGTitle key="texte" level={3} visuallevel={5} textalign="left">
            <FormattedMessage id="monObjectif.budget.titre" />
          </SGTitle>
          <LignesRevenus key="lignes" dataSource={dataSource} />
          <BoutonSupervision
            key="bouton"
            type="secondary"
            disabled={saisieEnErreur || objectifRevenu === undefined}
            onClick={handleOnClickPersonnaliser}
          >
            <FormattedMessage id="monObjectif.bouton.personnaliser" />
          </BoutonSupervision>
        </SGSpace>
      </SGBox>
      <DrawerBudget
        showDrawer={drawerVisible}
        setShowDrawer={setDrawerVisible}
        infosBudget={infosBudget}
      />
    </SGTile>
  );
};
