import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { AssuModal } from "website/components/molecules/AssuModal/AssuModal";
import { BESOIN_EPARGNE } from "website/utils/privateRoutes";

interface ModalBlocageNavigationProps {
  conditionBlocage: boolean;
  conditionDeblocage: boolean;
  deblocageManuel: boolean;
  onValiderModification: () => void;
}

export const ModalBlocageNavigation: FC<ModalBlocageNavigationProps> = ({
  conditionBlocage,
  conditionDeblocage,
  deblocageManuel,
  onValiderModification,
}) => {
  const { trackPopin } = useTracking();
  const history = useHistory();

  const [exitPath, setExitPath] = useState<string>();
  const [showExitModal, setShowExitModal] = useState(false);
  const [unblock, setUnblock] = useState<() => void>(); // UnregisterCallback

  const initUnblock = useCallback(
    () =>
      // Bloquage du parcours pour confirmation
      history.block(({ pathname }) => {
        if (conditionDeblocage && unblock) {
          unblock();

          history.push(pathname);
        }

        // On ignore
        if (pathname !== BESOIN_EPARGNE) {
          // Sortie non autorisée
          setExitPath(pathname);
          setShowExitModal(true);
          window.location.href = `#${BESOIN_EPARGNE}`;
        }

        return false;
      }),
    [history, conditionDeblocage, unblock]
  );

  useEffect(() => {
    if (showExitModal) {
      trackPopin(false, "", "popin-modification-non-enregistree");
    }
  }, [showExitModal]);

  useEffect(() => {
    if (deblocageManuel && unblock) {
      unblock();
    }
  }, [deblocageManuel, unblock]);

  useEffect(() => {
    // Déverrouillage si mise à jour des postes ou erreur de saisie
    // reset est false à l'init du composant, le temps des chargements
    if (conditionDeblocage && unblock) {
      unblock();
      setUnblock(undefined);
    }

    // Verrouillage si mise à jour d'objectif et saisie sans erreur
    if (conditionBlocage && unblock === undefined) {
      setUnblock(initUnblock);
    }
  }, [conditionBlocage, conditionDeblocage, initUnblock, unblock]);

  const debloquerEtRediriger = () => {
    if (unblock) {
      unblock();
    }
    if (exitPath) {
      history.push(exitPath);
    }
    setShowExitModal(false);
  };

  /**
   * Confirmation de l'abandon du parcours
   */
  const handleOnClickAnnulerSaisie = () => {
    trackPopin(
      true,
      "",
      "popin-modification-non-enregistree::clic-sur-annuler-ma-saisie"
    );
    debloquerEtRediriger();
  };

  /**
   * Confirmation de l'annulation
   */
  const handleOnClickEnregistrerSaisie = () => {
    trackPopin(
      true,
      "",
      "popin-modification-non-enregistree::clic-sur-enregistrer-ma-saisie"
    );
    onValiderModification();
    debloquerEtRediriger();
  };

  return (
    <AssuModal
      type="exitBudget"
      visible={showExitModal}
      closable={false}
      onClickLeftButton={handleOnClickEnregistrerSaisie}
      onClickRightButton={handleOnClickAnnulerSaisie}
      isSubtitle
    />
  );
};
