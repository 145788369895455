import debounce from "lodash.debounce";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGPopover } from "sg-popover";
import { SGBox } from "sg-space";
import { SGTabPane, SGTabs } from "sg-tabs";
import { SGText } from "sg-typo";
import { resetPostesDepenses, setMontantEpargneBudgetErreur, setMontantEpargneBudgetOK, setObjectifRevenuBudgetErreur, setObjectifRevenuBudgetOK } from "store/budgetRetraite/budgetRetraiteSlice";
import { BudgetRetraiteState } from "store/budgetRetraite/types";
import { DashboardAge, hasFetchedDashboard } from "store/dashboard/types";
import { getMonthlyIncome, getSavingEffort, resetErrorEfforts } from "store/savingPath/actions";
import {
    MonthlyIncomeRequest,
    SavingEffortRequest,
    SavingPathState,
    convertAgeTypeToWealthProfile,
    getEffortFromCache
} from "store/savingPath/types";
import { State } from "store/store";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useMontantsMax } from "website/components/hooks/dashboard/useMontantsMax";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { EffortEpargneTab } from "./EffortEpargneTab/EffortEpargneTab";

export enum OngletEffortEpargne {
    objectif = "objectif",
    epargne = "epargne",
}

interface EffortEpargneProps {
    selectedRetirementAge: DashboardAge;
    ongletDefaut: OngletEffortEpargne
    onTabChange: (onglet: OngletEffortEpargne) => void;
}

/**
 * Composant chapeau de l'objectif/épargne du Dashboard.
 * Permet de gérer le fonctionnel et la gestion des deux onglets.
 */
const EffortEpargne: FunctionComponent<EffortEpargneProps> = (props: EffortEpargneProps) => {
    const { selectedRetirementAge, ongletDefaut, onTabChange } = props;
    const intl = useIntl();

    const { resultsEfforts, resultsObjectives } = useSelector<
        State,
        SavingPathState
    >((state) => state.savingPath);

    const dashboardState = useDashboardState();
    const { minObjectifInput, maxObjectifInput, minEpargneInput, maxEpargneInput } =
        useMontantsMax();

    const minMessageObjectif = useMemo(
      () =>
        intl.formatMessage(
            { id: "monObjectif.effortEpargne.objectif.min" },
            { min: minObjectifInput.toLocaleString("fr-FR") }
        ),
      [intl, minObjectifInput]
    );
    const maxMessageObjectif = useMemo(
      () =>
        intl.formatMessage(
            { id: "monObjectif.effortEpargne.objectif.max" },
            { max: maxObjectifInput.toLocaleString("fr-FR") }
        ),
      [intl, maxObjectifInput]
    );
    const maxMessageEpargne = useMemo(
      () =>
        intl.formatMessage(
            { id: "monObjectif.effortEpargne.epargne.max" },
            { max: maxEpargneInput.toLocaleString("fr-FR") }
        ),
      [intl, maxEpargneInput]
    );

    const dispatch = useDispatch();

    const [currentTab, setCurrentTab] =
    useState<OngletEffortEpargne>(ongletDefaut);
    const fromObjectif = currentTab === OngletEffortEpargne.objectif;
    const wealthProfile = useMemo(
        () => convertAgeTypeToWealthProfile(selectedRetirementAge.type),
        [selectedRetirementAge.type]
    );

    const {
        saisieEnErreur,
        objectifRevenu,
        montantEpargne,
        postesDepenses
    }: BudgetRetraiteState = useSelector<State, BudgetRetraiteState>(
        (state) => state.budgetRetraite
    );
    const [savedObjectifRevenu, setSavedObjectifRevenu] = useState<number>();
    const [savedMontantEpargne, setSavedMontantEpargne] = useState<number>();
    const totalDepensesPersistees = useMemo(() => {
        if (postesDepenses) {
            return (
                postesDepenses.montantLogement +
                postesDepenses.montantQuotidien +
                postesDepenses.montantTransports +
                postesDepenses.montantLoisirs +
                postesDepenses.montantSante +
                postesDepenses.montantImpots
            );
        }

        return undefined;
    }, [postesDepenses]);

    const effortEpargneCalcule = useMemo(() => {
        if (!selectedRetirementAge?.type || !objectifRevenu) {
            return undefined;
        }

        return getEffortFromCache(
            resultsEfforts,
            selectedRetirementAge.type,
            objectifRevenu
        );
    }, [resultsEfforts, selectedRetirementAge?.type, objectifRevenu]);

    const objectifRevenuCalcule = useMemo(() => {
        if (!selectedRetirementAge?.type || montantEpargne === undefined) {
            return undefined;
        }

        return getEffortFromCache(
            resultsObjectives,
            selectedRetirementAge.type,
            montantEpargne
        );
    }, [resultsObjectives, selectedRetirementAge?.type, montantEpargne]);

    const setObjectifRevenu = useCallback(
        (objectif?: number) => {
            if (objectif === undefined || (
                objectif >= minObjectifInput &&
                objectif <= maxObjectifInput
            )) {
                dispatch(setObjectifRevenuBudgetOK(objectif));
            } else {
                dispatch(setObjectifRevenuBudgetErreur(objectif));
            }
            if (objectif !== totalDepensesPersistees) {
                dispatch(resetPostesDepenses());
            }
        },
        [minObjectifInput, maxObjectifInput, dispatch, totalDepensesPersistees]
    );

    const setMontantEpargne = useCallback((epargne?: number) => {
        if (epargne === undefined || (
            epargne >= minEpargneInput &&
            epargne <= maxEpargneInput
        )) {
            dispatch(setMontantEpargneBudgetOK(epargne));
        } else {
            dispatch(setMontantEpargneBudgetErreur(epargne));
        }
    }, [dispatch, minEpargneInput, maxEpargneInput]);

    const [hasChangedValueObjectifRevenu, setHasChangedValueObjectifRevenu] = useState<boolean>(false);
    const [hasChangedValueMontantEpargne, setHasChangedValueMontantEpargne] = useState<boolean>(false);

    const { trackTab, trackDisclaimer } = useTracking();

    /**
     * Décale l'appel au moteur et annule les appels successifs trop courts
     */
    const debouncedCallEffortEpargne = useCallback(
        debounce((val: number, fromMonthlyEffort: boolean, wp: string) =>
            callSavingPathMotor(val, fromMonthlyEffort, wp), 400),
        [] // will be created only once initially
    );

    /**
     * Init avec infos du dashboard => Mise à jour de l'objectif et récupération de l'effort d'épargne (ou l'inverse)
     */
    useEffect(() => {
        if (hasFetchedDashboard(dashboardState) && savedObjectifRevenu === undefined) {
            if (dashboardState.dashboard.monthlyIncomeGoal !== undefined) {
                setObjectifRevenu(dashboardState.dashboard.monthlyIncomeGoal);
            }
        }
    // Un seul appel à l'init
    }, [dashboardState]);

    useEffect(() => {
        if ((savedObjectifRevenu !== undefined && objectifRevenu === undefined)
        || (savedMontantEpargne !== undefined && montantEpargne === undefined)) {
            setSavedObjectifRevenu(undefined);
            setSavedMontantEpargne(undefined);
            setObjectifRevenu();
            setMontantEpargne();
        }
    }, [savedObjectifRevenu, objectifRevenu, savedMontantEpargne, montantEpargne, setObjectifRevenu, setMontantEpargne]);

    // Si on édite l'objectif, on remet à zéro l'erreur de calcul
    useEffect(() => {
        if (fromObjectif && savedObjectifRevenu !== objectifRevenu) {
            dispatch(resetErrorEfforts());
        }
    }, [fromObjectif, objectifRevenu, savedObjectifRevenu, dispatch])

    // Si on édite l'objectif, on calcule le montant d'épargne
    useEffect(() => {
        if (fromObjectif && savedObjectifRevenu !== objectifRevenu && (
            objectifRevenu !== undefined && objectifRevenu >= minObjectifInput && objectifRevenu <= maxObjectifInput)
            // Déjà en cache
            && effortEpargneCalcule === undefined) {
            debouncedCallEffortEpargne(objectifRevenu ?? 0, false, wealthProfile);
        }
    }, [fromObjectif, objectifRevenu, savedObjectifRevenu, minObjectifInput, maxObjectifInput, wealthProfile, debouncedCallEffortEpargne, effortEpargneCalcule]);

    // Si on édite le montant d'épargne, on calcule l'objectif
    useEffect(() => {
        if (!fromObjectif && savedMontantEpargne !== montantEpargne && (
            montantEpargne !== undefined && montantEpargne <= maxEpargneInput)
            // Déjà en cache
            && objectifRevenuCalcule === undefined) {
            // Si on édite le montant d'épargne, on calcule l'objectif
            debouncedCallEffortEpargne(montantEpargne ?? 0, true, wealthProfile);
        }
    }, [fromObjectif, objectifRevenu, montantEpargne, maxEpargneInput, savedMontantEpargne, wealthProfile, debouncedCallEffortEpargne, objectifRevenuCalcule]);

    // Déclencher uniquement la première fois sur la page
    useEffect(() => {
        if (hasChangedValueObjectifRevenu) {
            trackDisclaimer("disclaimer-resultats-saisie-objectif-revenus");
        }
    }, [hasChangedValueObjectifRevenu]);

    // Déclencher uniquement la première fois sur la page
    useEffect(() => {
        if (hasChangedValueMontantEpargne) {
            trackDisclaimer("disclaimer-resultats-saisie-objectif-epargne");
        }
    }, [hasChangedValueMontantEpargne]);

    // Récupération de l'objectif via le moteur
    useEffect(() => {
        if (!fromObjectif && montantEpargne !== undefined && objectifRevenuCalcule !== undefined) {
            setObjectifRevenu(objectifRevenuCalcule);
            setSavedObjectifRevenu(objectifRevenuCalcule);
            // Appel OK, on sauvegarde le montant saisi
            setSavedMontantEpargne(montantEpargne);
        }
    }, [fromObjectif, objectifRevenuCalcule, montantEpargne, savedObjectifRevenu, setObjectifRevenu]);

    // Récupération de l'effort d'épargne via le moteur
    useEffect(() => {
        if (fromObjectif && objectifRevenu !== undefined && effortEpargneCalcule !== undefined) {
            setMontantEpargne(effortEpargneCalcule);
            setSavedMontantEpargne(effortEpargneCalcule);
            // Appel OK, on sauvegarde le montant saisi
            setSavedObjectifRevenu(objectifRevenu);
        }
    }, [fromObjectif, effortEpargneCalcule, objectifRevenu, savedMontantEpargne, setMontantEpargne]);

    const changeTab = (target: OngletEffortEpargne) => {
        if (target !== currentTab
            // On bloque le changement d'onglet le temps de l'appel au moteur
            && (objectifRevenu === savedObjectifRevenu)
            && (montantEpargne === savedMontantEpargne)) {
            setCurrentTab(target);
            onTabChange(target);
            // Tracking [Onglet] Changement d'onglet "revenus" / "épargne"
            trackTab(`clic-sur-onglet-${target === OngletEffortEpargne.objectif ? "mon-besoin-de-revenus" : "mon-epargne"}`);

            // On arrondit l'autre valeur au changement d'onglet, pour ne pas avoir d'unité
            // Seulement si elle est définie
            if (fromObjectif && montantEpargne) {
                roundMontantEpargne(montantEpargne);
            } else if (!fromObjectif && objectifRevenu) {
                roundObjectifRevenu(objectifRevenu);
            }

            // Si changement d'onglet pendant un recalcul, on remet l'ancienne valeur
            if (fromObjectif && objectifRevenu !== savedObjectifRevenu) {
                setObjectifRevenu(savedObjectifRevenu);
            } else if (!fromObjectif && montantEpargne !== savedMontantEpargne) {
                setMontantEpargne(savedMontantEpargne);
            }
        }
    };

    const roundObjectifRevenu = (objectif: number) => {
        setObjectifRevenu(objectif);
    };

    const roundMontantEpargne = (epargne: number) => {
        setMontantEpargne(roundDown10(epargne ?? 0));
    };

    /**
     * Appelle le moteur dans un sens ou dans l'autre
     *
     * @param val Valeur à envoyer
     * @param fromMonthlyEffort Si oui, on envoie l'effort d'épargne
     * @param wp Profil Wealth
     */
    const callSavingPathMotor = (val: number, fromMonthlyEffort: boolean, wp: string) => {
        if (fromMonthlyEffort) {
            const req: MonthlyIncomeRequest = {
                profile: wp,
                monthlySavingEffort: val,
            };
            dispatch(getMonthlyIncome(req));
        } else {
            const req: SavingEffortRequest = {
                profile: wp,
                monthlyIncomeObjective: val,
            };
            dispatch(getSavingEffort(req));
        }
    };

    return (
        <SGTabs
            centered
            disableautomargin
            activeKey={currentTab}
            onChange={(onglet: OngletEffortEpargne) => changeTab(onglet)}
        >
            <SGTabPane
                ariaId={OngletEffortEpargne.objectif}
                tab={intl.formatMessage({ id: `monObjectif.effortEpargne.objectif.tab` })}
                key={OngletEffortEpargne.objectif}
            >
                {currentTab === OngletEffortEpargne.objectif && (
                    <EffortEpargneTab
                        currentTab={currentTab}
                        editableValue={objectifRevenu}
                        setEditableValue={setObjectifRevenu}
                        texteSousInput={
                           !saisieEnErreur && objectifRevenu && selectedRetirementAge?.lastMonthlyIncome > 0 ? (
                              <SGBox margin={{ top: "xs" }}>
                                <SGText>
                                  <FormattedMessage
                                    id="monObjectif.effortEpargne.objectif.sousTexte"
                                    values={{
                                      objectif: Math.round(
                                        ((objectifRevenu ?? 0) / selectedRetirementAge.lastMonthlyIncome) * 100
                                      ),
                                    }}
                                  />{" "}
                                  <SGPopover
                                    placement="bottomRight"
                                    onOpenChange={() => true}
                                    trigger={{ hover: true, click: true, focus: true }}
                                    content={
                                      <FormattedMessage id="monObjectif.effortEpargne.objectif.infobulle" />
                                    }
                                  >
                                    <SGButton
                                      icon={
                                        <SGIcon
                                          component={<SGAvenirStatusInfo />}
                                          iconName="SGAvenirStatusInfo"
                                          size="m"
                                        />
                                      }
                                      type="icon"
                                    />
                                  </SGPopover>
                                </SGText>
                              </SGBox>
                            ) : undefined}
                        minValueInput={minObjectifInput}
                        minMessage={minMessageObjectif}
                        maxValueInput={maxObjectifInput}
                        maxMessage={maxMessageObjectif}
                        setHasChangedValue={setHasChangedValueObjectifRevenu}
                    />
                )}
            </SGTabPane>
            <SGTabPane
                ariaId={OngletEffortEpargne.epargne}
                tab={intl.formatMessage({ id: `monObjectif.effortEpargne.epargne.tab` })}
                key={OngletEffortEpargne.epargne}
            >
                {currentTab === OngletEffortEpargne.epargne && (
                    <EffortEpargneTab
                        currentTab={currentTab}
                        editableValue={montantEpargne}
                        setEditableValue={setMontantEpargne}
                        minValueInput={minEpargneInput}
                        maxValueInput={maxEpargneInput}
                        maxMessage={maxMessageEpargne}
                        setHasChangedValue={setHasChangedValueMontantEpargne}
                    />
                )}
            </SGTabPane>
        </SGTabs>
    );
};

export { EffortEpargne };
