import { FunctionComponent, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGTile } from "sg-tile";
import { SGText, SGTitle } from "sg-typo";
import { ImportState } from "store/import/types";
import {
  RetirementCategory,
  RetirementCategorySimulation,
} from "store/members/types";
import { CategorieActiveChoix } from "store/simulateurAvantApres/types";
import { State } from "store/store";
import { previousStepAction } from "store/tunnel/actions";
import { fetchWorkIncomesHistory } from "store/workIncomesHistory/action";
import {
  WorkIncomeRaisesType,
  WorkIncomesHistory,
} from "store/workIncomesHistory/types";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { Loader } from "website/components/atoms/Loader/Loader";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFamily } from "website/components/hooks/useFamily";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";
import { InfoAssuModal } from "website/components/molecules/InfoAssuModal/InfoAssuModal";
import { EvolutionsInput } from "website/components/molecules/SimulateurInputs/EvolutionsInput";
import { CategorieActiveInput } from "../../molecules/SimulateurInputs/CategorieActiveInput";
import { DateDebutCarriereInput } from "../../molecules/SimulateurInputs/DateDebutCarriereInput";
import { RevenusInput } from "../../molecules/SimulateurInputs/RevenusInput";
import { SituationInput } from "../../molecules/SimulateurInputs/SituationInput";
import { getTextStrong } from "website/utils/formatting/ValuesFormattedMessage";

interface WorkIncomesProps {
  onSubmit: (data: WorkIncomesHistory) => void;
  showPopin: boolean;
  onHideModal: () => void;
  carrierStartYears?: number;
  setCarrierStartYears: (value: number) => void;
  carrierStartMonth?: number;
  setCarrierStartMonth: (value: number) => void;
  montantSalaireAnnuel?: number;
  setMontantSalaireAnnuel: (value: number | undefined) => void;
  secteurPro?: string;
  setSecteurPro: (value: string | undefined) => void;
  setMemberId: (value: number | undefined) => void;
  retirementType?: RetirementCategory;
  setRetirementType: (value: RetirementCategory) => void;
  categorieActive: CategorieActiveChoix;
  setCategorieActive: (value: CategorieActiveChoix) => void;
  pastForecast?: WorkIncomeRaisesType;
  setPastForecast: (value: WorkIncomeRaisesType) => void;
  futureForecast?: WorkIncomeRaisesType;
  setFutureForecast: (value: WorkIncomeRaisesType) => void;
  hasSubmitStep: boolean;
  errorHappened: boolean;
  showErrorModal: boolean;
  handleError: (value: boolean) => void;
}

export const WorkIncomes: FunctionComponent<WorkIncomesProps> = (
  props: WorkIncomesProps
) => {
  const dispatch = useDispatch();
  const {
    onSubmit,
    showPopin,
    onHideModal,
    carrierStartYears,
    setCarrierStartYears,
    carrierStartMonth,
    setCarrierStartMonth,
    montantSalaireAnnuel,
    setMontantSalaireAnnuel,
    secteurPro,
    setSecteurPro,
    setMemberId,
    retirementType,
    setRetirementType,
    categorieActive,
    setCategorieActive,
    pastForecast,
    setPastForecast,
    futureForecast,
    setFutureForecast,
    hasSubmitStep,
    errorHappened,
    showErrorModal,
    handleError,
  } = props;

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const family = useFamily();
  const { trackClick } = useTracking();

  const importData: ImportState = useSelector<State, ImportState>(
    (state) => state.importData
  );

  const methods = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { handleSubmit } = methods;

  useEffect(() => {
    dispatch(fetchWorkIncomesHistory());
  }, []);

  useEffect(() => {
    setSecteurPro(
      retirementType && RetirementCategorySimulation[retirementType]
    );
  }, [retirementType, setSecteurPro]);

  useEffect(() => {
    if (family?.me) {
      setMemberId(family.me.id);
    }
  }, [family, importData, setMemberId]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SGSpace direction="vertical" disableautomargin size="xl">
          <SGSpace key="space-titre" direction="vertical" size="sm">
            <SGTitle key="titre" level={2}>
              <FormattedMessage id="tunnel.workIncomesHistory.title" />
            </SGTitle>
            <SGText key="text" size={isPhone ? "l" : undefined}>
              <FormattedMessage id="tunnel.workIncomesHistory.subtitle" />
            </SGText>
          </SGSpace>
          <SGSpace key="space-carriere" direction="vertical" size="md">
            <SGTitle key="titre" level={3} visuallevel={isPhone ? 3 : 4}>
              <FormattedMessage id="tunnel.workIncomesHistory.carriere.title" />
            </SGTitle>
            <SituationInput
              key="situation"
              setCategorieActive={setCategorieActive}
              setRetirementType={setRetirementType}
              retirementType={retirementType}
            />
            <CategorieActiveInput
              key="categorie"
              setCategorieActive={setCategorieActive}
              categorieActive={categorieActive}
              retirementType={retirementType}
            />
            <SGBox key="date" span={isPhone ? 12 : 5}>
              <DateDebutCarriereInput
                carrierStartMonth={carrierStartMonth}
                setCarrierStartMonth={setCarrierStartMonth}
                carrierStartYears={carrierStartYears}
                setCarrierStartYears={setCarrierStartYears}
              />
            </SGBox>
            <RevenusInput
              key="revenus"
              montantSalaireAnnuel={montantSalaireAnnuel}
              setMontantSalaireAnnuel={setMontantSalaireAnnuel}
              secteurPro={secteurPro}
            />
          </SGSpace>
          <SGSpace key="space-evolution" direction="vertical" size="md">
            <SGTitle key="titre" level={3} visuallevel={isPhone ? 3 : 4}>
              <FormattedMessage id="tunnel.workIncomesHistory.evolution.title" />
            </SGTitle>
            <SGBox
              key="evolutionsPassees"
              padding={isPhone ? {} : { bottom: "xs" }}
            >
              <EvolutionsInput
                name="pastForecast"
                label="tunnel.workIncomesForecast.evolutionSalairePasse"
                forecast={pastForecast}
                setForecast={setPastForecast}
              />
            </SGBox>
            <EvolutionsInput
              name="futureForecast"
              label="tunnel.workIncomesForecast.evolutionSalaireFutur"
              key="evolutionsFutures"
              forecast={futureForecast}
              setForecast={setFutureForecast}
            />
          </SGSpace>
          <SGTile key="bonASavoir">
            <SGText>
              <FormattedMessage
                id="tunnel.workIncomesForecast.bonASavoir"
                values={{ b: getTextStrong }}
              />
            </SGText>
          </SGTile>
          {!hasSubmitStep ? (
            <SGBox key="submit" margin={{ top: "xl" }}>
              <SGButtonGroup key="buttons" align="center" disableautomargin>
                <BoutonSupervision
                  type="primary"
                  onClick={handleSubmit(onSubmit)}
                  size="md"
                >
                  {errorHappened ? (
                    <FormattedMessage id="tunnel.workIncomesForecast.button.retry" />
                  ) : (
                    <FormattedMessage id="tunnel.buttons.next" />
                  )}
                </BoutonSupervision>
                <SGButton
                  type="secondary"
                  onClick={() => {
                    trackClick("clic-sur-retour");
                    dispatch(previousStepAction());
                  }}
                  size="md"
                >
                  <SGText>
                    <FormattedMessage id="tunnel.buttons.previous" />
                  </SGText>
                </SGButton>
              </SGButtonGroup>
            </SGBox>
          ) : (
            <SGBox key="loader" margin={{ top: "sm" }}>
              <Loader title="loader.title" />
            </SGBox>
          )}
        </SGSpace>
        {showErrorModal && (
          <ErrorModal visible={showErrorModal} setVisible={handleError} />
        )}
        <InfoAssuModal
          onHide={onHideModal}
          showInfoModal={showPopin}
          text="popin.info.montant.text"
          tranform={{ montant: montantSalaireAnnuel?.toLocaleString("fr-FR") }}
          title="modal.error.info.title"
          buttonPrimary="modal.error.button"
        />
      </form>
    </FormProvider>
  );
};
