import { FunctionComponent, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGAvenirStyledStetoscope } from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { CarteSimulateurEconomieImpot } from "website/components/atoms/CarteSimulateurEconomieImpot/CarteSimulateurEconomieImpot";
import { useDroitsSegment } from "website/components/hooks/useDroitsSegment";
import { useFeatures } from "website/components/hooks/useFeatures";
import { CarteOffreSapiendo } from "../CarteOffreSapiendo/CarteOffreSapiendo";
import { CartePortailInfoRetraite } from "../CartePortailInfoRetraite/CartePortailInfoRetraite";
import { CarteSGAgenda } from "../CarteSGAgenda/CarteSGAgenda";
import { TuileASV } from "../TuileASV/TuileASV";
import { TuileDiagnosticEpargne } from "../TuileDiagnosticEpargne/TuileDiagnosticEpargne";
import { TuilePER } from "../TuilePER/TuilePER";
import { TuileSolutionsEpargne } from "../TuileSolutionsEpargne/TuileSolutionsEpargne";
import { NomPageEnum } from "./utils";

export enum AllerPlusLoinTuile {
  agenda,
  diagEpargne,
  solutions,
  sei,
  per,
  asv,
  infoRetraite,
  sapiendo,
}

interface SectionAllerPlusLoinProps {
  nom: NomPageEnum;
  ordreTuilesPage: AllerPlusLoinTuile[];
  isSousTitre?: boolean;
}

const SectionAllerPlusLoin: FunctionComponent<SectionAllerPlusLoinProps> = (
  props: SectionAllerPlusLoinProps
) => {
  const { nom, ordreTuilesPage, isSousTitre = false } = props;
  const intl = useIntl();
  const { isDiagnosticEpargneAvailable } = useDroitsSegment();
  const { parcoursSEIActif } = useFeatures();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
  const isTablet = useMediaQuery({ minwidth: "md", maxwidth: "lg" });

  /**
   * Génère une tuile à la demande
   */
  const getTuile = useCallback(
    (typeTuile: AllerPlusLoinTuile) => {
      switch (typeTuile) {
        case AllerPlusLoinTuile.agenda:
          return (
            <CarteSGAgenda
              key="carteSGAgenda"
              alignchildren="center"
              idTracking="clic-sur-card-contacter-un-conseiller-sg"
            />
          );
        case AllerPlusLoinTuile.diagEpargne:
          return isDiagnosticEpargneAvailable ? (
            <TuileDiagnosticEpargne
              key="diag-epargne"
              icone={<SGAvenirStyledStetoscope />}
              iconeTaille="s"
              alignchildren="center"
              idTracking="clic-sur-card-diagnostic-epargne"
            />
          ) : null;
        case AllerPlusLoinTuile.sei:
          return parcoursSEIActif ? (
            <CarteSimulateurEconomieImpot key="carteSimulateurEconomieImpot" />
          ) : null;
        case AllerPlusLoinTuile.solutions:
          return (
            <TuileSolutionsEpargne
              key="solutionsEpargne"
              alignchildren="center"
              idTracking="clic-sur-card-nos-solutions-d-epargne"
            />
          );
        case AllerPlusLoinTuile.per:
          return <TuilePER key="tuilePER" />;
        case AllerPlusLoinTuile.asv:
          return <TuileASV key="tuileASV" />;
        case AllerPlusLoinTuile.infoRetraite:
          return <CartePortailInfoRetraite key="infoRetraite" />;
        case AllerPlusLoinTuile.sapiendo:
          return (
            <CarteOffreSapiendo
              key="carteSapiendo"
              idTracking="clic-sur-card-sapiendo-expert-retraite"
            />
          );
        default:
          return null;
      }
    },
    [isDiagnosticEpargneAvailable, parcoursSEIActif]
  );

  const tuilesPage = useMemo(
    () => ordreTuilesPage.map(getTuile).filter((carte) => carte !== null),
    [ordreTuilesPage, getTuile]
  );

  const largeurTuiles = useMemo(() => {
    if (isPhone) {
      return 12;
    }

    return isTablet ? 8 : Math.max(tuilesPage.length, 1) * 4;
  }, [isPhone, isTablet, tuilesPage]);

  return (
    <SGBox>
      <>
        <SGSpace direction="vertical" size="sm" disableautomargin>
          <SGTitle key="titre" level={2} visuallevel={isPhone ? 2 : 3}>
            {intl.formatMessage({
              id: `${nom}.section.allerPlusLoin.titre`,
            })}
          </SGTitle>
          {isSousTitre && (
            <SGText key="text" size="l">
              <FormattedMessage id={`${nom}.section.allerPlusLoin.sousTitre`} />
            </SGText>
          )}
        </SGSpace>
        <SGBox
          margin={
            nom === NomPageEnum.SYNTHESE_RETRAITE
              ? { top: "sm" }
              : { top: "lg" }
          }
        >
          <SGGridRow justify="center">
            <SGGridCol span={largeurTuiles}>
              <SGSpace
                xs={{ direction: "vertical" }}
                xl={{ direction: "horizontal", size: "md" }}
                align="stretch"
                disableautomargin
              >
                {tuilesPage}
              </SGSpace>
            </SGGridCol>
          </SGGridRow>
        </SGBox>
      </>
    </SGBox>
  );
};

export { SectionAllerPlusLoin };
