import { DashboardAgeType } from "store/dashboard/types";

export interface BudgetRetraiteState {
  typeDepart?: DashboardAgeType;
  epargneMaximale?: number;

  objectifRevenu?: number;
  montantEpargne?: number;
  saisieEnErreur: boolean;
  hasFetched: boolean;
  postesDepenses?: PostesDepensesDTO;
  postesDepensesPersistes?: PostesDepensesDTO;
}

export interface PostesDepensesDTO {
  montantLogement: number;
  montantQuotidien: number;
  montantTransports: number;
  montantLoisirs: number;
  montantSante: number;
  montantImpots: number;
}

export enum PosteDepense {
  logement = "logement",
  quotidien = "quotidien",
  transports = "transports",
  loisirs = "loisirs",
  sante = "sante",
  impots = "impots",
}

export interface InfoBudget {
  posteDepense: PosteDepense;
  pourcentage: number;
  montant?: number;
}

export const FETCH_POSTES_DEPENSES = "FETCH_POSTES_DEPENSES";
export const POST_POSTES_DEPENSES = "POST_POSTES_DEPENSES";
export const DELETE_POSTES_DEPENSES = "DELETE_POSTES_DEPENSES";
