import { PayloadAction } from "@reduxjs/toolkit";
import { Reducer } from "react";
import { EFFORTS_FETCHED, EffortState, Result, SAVINGPATHS_FETCHING, SAVINGPATHS_FROM_EFFORT_FETCHED, SAVINGPATHS_FROM_OBJECTIVE_FETCHED, SAVINGPATH_ERROR_RESET, SAVINGPATH_FROM_EFFORT_FETCHED, SAVINGPATH_FROM_OBJECTIVE_FETCHED, SAVINGPATH_GET_EFFORT_ERROR, SAVINGPATH_RESULTS_RESET, SavingPathState } from "./types";

const initialSavingPathState: SavingPathState = {
   loading: false,
   results: [] as Result[],
   resultsEfforts: [] as Result[],
   resultsObjectives: [] as Result[],
} as SavingPathState;
const initialEffortState: EffortState = {} as EffortState;

export const SavingPathReducer: Reducer<SavingPathState, PayloadAction<any>> = (
   currentState = initialSavingPathState,
   action
): SavingPathState => {
   switch (action.type) {
      case SAVINGPATHS_FETCHING:
         return {
            ...currentState,
            loading: true,
         }
      case SAVINGPATH_GET_EFFORT_ERROR:
         return {
            ...currentState,
            loading: false,
            monthlyEffortError: action.payload,
         }
      case SAVINGPATHS_FROM_EFFORT_FETCHED:
      case SAVINGPATHS_FROM_OBJECTIVE_FETCHED:
         return {
            ...currentState,
            results: action.payload,
            loading: false,
         };
      case SAVINGPATH_RESULTS_RESET:
         return {
            ...currentState,
            results: [],
            resultsEfforts: [],
            resultsObjectives: [],
            monthlyEffortError: undefined,
         }
      case SAVINGPATH_ERROR_RESET:
         return {
            ...currentState,
            monthlyEffortError: undefined,
         }
      case SAVINGPATH_FROM_EFFORT_FETCHED:
         return {
            ...currentState,
            objective: action.payload.result,
            resultsObjectives: computeCacheEffort(currentState.resultsObjectives, action.payload),
            loading: false,
         };
      case SAVINGPATH_FROM_OBJECTIVE_FETCHED:
         return {
            ...currentState,
            monthlyEffort: action.payload.result,
            resultsEfforts: computeCacheEffort(currentState.resultsEfforts, action.payload),
            monthlyEffortError: undefined,
            loading: false,
         }
      default:
         return currentState;
   }
};

export const EffortReducer: Reducer<EffortState, any> = (
   currentState = initialEffortState,
   action
): EffortState => {
   switch (action.type) {
      case EFFORTS_FETCHED: {
         return {
            ...currentState,
            results: action.payload,
         };
      }
      default:
         return currentState;
   }
};

const computeCacheEffort = (results: Result[], payload: any): Result[] => {
   const result = payload as Result;
   const resultsFiltres = results.filter((r: Result) => r.age !== result.age || r.source !== result.source);

   return [
      ...resultsFiltres,
      result,
   ];
}
