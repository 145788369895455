import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiDelete, apiGet, apiPost } from "../apiCaller";
import { onDefaultError, safe } from "../error/utils";
import { postesDepensesFetched } from "./budgetRetraiteSlice";
import {
  DELETE_POSTES_DEPENSES,
  FETCH_POSTES_DEPENSES,
  POST_POSTES_DEPENSES,
  PostesDepensesDTO,
} from "./types";

const URL_API_BUDGET_RETRAITE = "backpfr/api/budget-retraite";

export function* watcherSaga() {
  yield takeLeading(
    FETCH_POSTES_DEPENSES,
    safe(onDefaultError, handleGetPostesDepenses)
  );
  yield takeLeading(
    POST_POSTES_DEPENSES,
    safe(onDefaultError, handlePostPostesDepenses)
  );
  yield takeLeading(
    DELETE_POSTES_DEPENSES,
    safe(onDefaultError, handleDeletePostesDepenses)
  );
}

function* handleGetPostesDepenses() {
  const payload: AxiosResponse<PostesDepensesDTO> = yield call(
    apiGet,
    URL_API_BUDGET_RETRAITE
  );

  if (payload.data.montantTransports === undefined) {
    yield put(postesDepensesFetched(undefined));
  } else {
    yield put(postesDepensesFetched(payload.data));
  }
}

function* handlePostPostesDepenses(action: PayloadAction<PostesDepensesDTO>) {
  const payload: AxiosResponse<PostesDepensesDTO> = yield call(
    apiPost,
    URL_API_BUDGET_RETRAITE,
    action.payload
  );
  yield put(postesDepensesFetched(payload.data));
}

function* handleDeletePostesDepenses() {
  yield call(apiDelete, URL_API_BUDGET_RETRAITE);
  yield put(postesDepensesFetched(undefined));
}
