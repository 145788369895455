import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGModal } from "sg-modal";
import { SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";

interface AssuModalProps {
  type: "exitRis" | "exitBudget";
  visible: boolean;
  closable: boolean;
  onClickLeftButton: () => void;
  onClickRightButton: () => void;
  onCancel?: () => void;
  isSubtitle?: boolean;
}

const AssuModal: FunctionComponent<AssuModalProps> = (
  props: AssuModalProps
) => {
  const {
    type,
    visible,
    closable,
    onClickLeftButton,
    onClickRightButton,
    onCancel,
    isSubtitle,
  } = props;

  return (
    <SGModal visible={visible} closable={closable} onCancel={onCancel}>
      <SGSpace direction="vertical" textalign="left">
        <SGTitle key="title" level={3} textalign="left">
          <FormattedMessage id={`assuModal.${type}.title`} />
        </SGTitle>
        {isSubtitle && (
          <SGText key="subtitle" size="m">
            <FormattedMessage id={`assuModal.${type}.subtitle`} />
          </SGText>
        )}
      </SGSpace>
      <SGButtonGroup align="center" layout="row">
        <SGButton type="primary" onClick={onClickRightButton}>
          <FormattedMessage id={`assuModal.${type}.button.right`} />
        </SGButton>
        <SGButton type="secondary" onClick={onClickLeftButton}>
          <FormattedMessage id={`assuModal.${type}.button.left`} />
        </SGButton>
      </SGButtonGroup>
    </SGModal>
  );
};

export { AssuModal };
