import { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import {
  SGAvenirNavFlecheBtnLien,
  SGAvenirStyledCorbeille,
  SGAvenirStyledDuree,
} from "sg-icon-pack-base";
import { SGContent, SGLayout } from "sg-layout";
import { SGLink } from "sg-link";
import { SGMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { AlertDeleteProfileModal } from "website/components/molecules/AlertDeleteProfileModal/AlertDeleteProfileModal";
import { TuileCliquable } from "website/components/molecules/TuileCliquable/TuileCliquable";
import { DASHBOARD, SIMULATEUR_FLASH } from "website/utils/privateRoutes";

const ModifierProfilPage: FunctionComponent = () => {
  const { trackClick, trackPage } = useTracking();
  const { disponibiliteSimulateurFlash } = useFeatures();
  const intl = useIntl();

  const [isAlertDeleteProfileModalVisible, setAlertDeleteProfileModalVisible] =
    useState(false);

  useEffect(() => {
    trackPage("modifier-mon-profil", "accueil", "gestion-ecran");
  }, []);

  const handleOnClickOpenModal = () => {
    setAlertDeleteProfileModalVisible(true);
    trackClick("clic-sur-supprimer-mes-donnees");
  };

  return (
    <SGLayout background="light">
      <SGContent disableautomargin>
        <SGGridRow justify="center">
          <SGGridCol span={12}>
            <SGSpace
              direction="vertical"
              align="center"
              textalign="center"
              disableautomargin
            >
              <SGTitle key="title" level={1} visuallevel={1}>
                <FormattedMessage id="modifierProfil.titre" />
              </SGTitle>
              <SGText key="text" size="l">
                <FormattedMessage id="modifierProfil.sousTitre" />
              </SGText>
            </SGSpace>
          </SGGridCol>

          <SGGridCol span={12} md={8}>
            {disponibiliteSimulateurFlash && (
              <TuileCliquable
                width={null}
                icone={<SGAvenirStyledDuree />}
                iconeTaille="s"
                titre={intl.formatMessage({
                  id: "modifierProfil.tuileModifier.titre",
                })}
                texte={intl.formatMessage({
                  id: "modifierProfil.tuileModifier.texte",
                })}
                onClick={() => trackClick("clic-sur-modifier-mes-informations")}
                lien={`#${SIMULATEUR_FLASH}`}
                ariaLabel={intl.formatMessage({
                  id: "modifierProfil.tuileModifier.titre",
                })}
              />
            )}

            <TuileCliquable
              width={null}
              icone={<SGAvenirStyledCorbeille />}
              iconeTaille="s"
              titre={intl.formatMessage({
                id: "modifierProfil.tuileSupprimer.titre",
              })}
              texte={intl.formatMessage({
                id: "modifierProfil.tuileSupprimer.texte",
              })}
              onClick={handleOnClickOpenModal}
              ariaLabel={intl.formatMessage({
                id: "modifierProfil.tuileSupprimer.titre",
              })}
            />
          </SGGridCol>

          <SGGridCol span={12}>
            <AlertDeleteProfileModal
              isVisible={isAlertDeleteProfileModalVisible}
              setVisible={setAlertDeleteProfileModalVisible}
            />

            <SGMediaQuery minwidth={0} maxwidth="sm">
              <SGBox margin={{ top: "xxl" }} textalign="center">
                <SGLink
                  href={`#${DASHBOARD}`}
                  type="tertiary"
                  icon={
                    <SGIcon
                      currentcolor
                      component={<SGAvenirNavFlecheBtnLien />}
                    />
                  }
                  iconside="right"
                  onClick={() =>
                    trackClick("clic-sur-retour-au-tableau-de-bord")
                  }
                >
                  <FormattedMessage id="modifierProfil.retour" />
                </SGLink>
              </SGBox>
            </SGMediaQuery>
          </SGGridCol>
        </SGGridRow>
      </SGContent>
      <SGBox margin={{ top: "xxl" }} padding={{ top: "xxl", bottom: "xxl" }} />
    </SGLayout>
  );
};

export { ModifierProfilPage };
