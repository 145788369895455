import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { SGDivider } from "sg-divider";
import { useMediaQuery } from "sg-media-query";
import { SGSkeleton } from "sg-skeleton";
import { SGBox, SGSpace } from "sg-space";
import { SGTile } from "sg-tile";
import { BudgetRetraiteState } from "store/budgetRetraite/types";
import { State } from "store/store";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { useMonPlanMontants } from "website/components/hooks/monPlan/useMonPlanMontants";
import { useParcoursPatrimoine } from "website/components/hooks/useParcoursPatrimoine";
import { useRevenuAFinancer } from "website/components/hooks/useRevenuAFinancer";
import {
  BESOIN_EPARGNE,
  PARCOURS_REVENUS_COMPLEMENTAIRES,
} from "website/utils/privateRoutes";
import {
  EffortEpargne,
  OngletEffortEpargne,
} from "../Dashboard/EffortEpargne/EffortEpargne";
import {
  LignesRevenus,
  RevenusElementListe,
} from "../Dashboard/Personnalisation/Revenus/LignesRevenus";
import { CadreInfoEpargne } from "./CadreInfoEpargne";

interface LignesRevenusProps {
  ongletDefaut: OngletEffortEpargne;
  onTabChange: (onglet: OngletEffortEpargne) => void;
  onClickBoutonDefaut: () => void;
}

export const TuileBesoinEpargne: FC<LignesRevenusProps> = ({
  ongletDefaut,
  onTabChange,
  onClickBoutonDefaut,
}) => {
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  const appelEnCours = useSelector<State, boolean>(
    (state) => state.savingPath.loading
  );

  const {
    saisieEnErreur,
    objectifRevenu,
    montantEpargne,
  }: BudgetRetraiteState = useSelector<State, BudgetRetraiteState>(
    (state) => state.budgetRetraite
  );
  const { revenuAFinancer } = useRevenuAFinancer();

  const { selectedRetirementAge } = useSelectedRetirementAge();
  const {
    montantRevenusComplementaires,
    montantPension,
    hasFetchedDashboardState,
    hasFetchedAssetIncomesState,
  } = useMonPlanMontants(
    selectedRetirementAge,
    0 // Pour ne pas déclencher l'appel au moteur
  );
  const { isAssetsChecked, isParcoursPatrimoineDone } = useParcoursPatrimoine();

  const dataSource: RevenusElementListe[] = useMemo(
    () => [
      {
        tag: "Ma pension",
        couleur: "26",
        montant: montantPension || 0,
        showMontant: true,
        showSkeleton: !hasFetchedDashboardState,
      },
      {
        tag: "Mon patrimoine",
        couleur: "36",
        montant: montantRevenusComplementaires || 0,
        showMontant: isParcoursPatrimoineDone && isAssetsChecked,
        lien: PARCOURS_REVENUS_COMPLEMENTAIRES,
        pageField3Tracking: "mon-patrimoine",
        showSkeleton: !hasFetchedAssetIncomesState,
      },
      {
        tag: "Mon épargne future",
        couleur: "11",
        montant: revenuAFinancer || 0,
        lien: BESOIN_EPARGNE,
        showMontant:
          revenuAFinancer !== undefined && objectifRevenu !== undefined,
        showSkeleton: appelEnCours,
      },
    ],
    [
      hasFetchedDashboardState,
      hasFetchedAssetIncomesState,
      montantPension,
      montantRevenusComplementaires,
      isParcoursPatrimoineDone,
      isAssetsChecked,
      revenuAFinancer,
      objectifRevenu,
      appelEnCours,
    ]
  );

  const estOngletEpargne = ongletDefaut === OngletEffortEpargne.epargne;
  const afficherCadre =
    !appelEnCours &&
    !saisieEnErreur &&
    objectifRevenu &&
    montantEpargne !== undefined &&
    !(estOngletEpargne && revenuAFinancer === 0); // Si 0, on n'affiche pas le cadre

  return (
    <SGTile clickable>
      <SGBox margin={isPhone ? undefined : { left: "xxl", right: "xxl" }}>
        <SGSpace direction="vertical">
          {selectedRetirementAge && ongletDefaut && (
            <EffortEpargne
              key="effort"
              selectedRetirementAge={selectedRetirementAge}
              ongletDefaut={ongletDefaut}
              onTabChange={onTabChange}
            />
          )}
          {ongletDefaut === OngletEffortEpargne.objectif && (
            <SGBox key="defaut" textalign="center">
              <BoutonSupervision type="secondary" onClick={onClickBoutonDefaut}>
                <FormattedMessage id="monObjectif.bouton.defaut" />
              </BoutonSupervision>
            </SGBox>
          )}
          {(appelEnCours || afficherCadre) && <SGDivider key="div1" />}
          {afficherCadre && (
            <CadreInfoEpargne key="cadre" ongletDefaut={ongletDefaut} />
          )}
          {appelEnCours && (
            <SGSkeleton
              key="skeleton"
              paragraph={{
                rows: 1,
              }}
            />
          )}
          <SGDivider key="div2" />
          <LignesRevenus key="lignes" dataSource={dataSource} />
        </SGSpace>
      </SGBox>
    </SGTile>
  );
};
