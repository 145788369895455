import { FunctionComponent } from "react";
import { SGSpace } from "sg-space";
import { DashboardAge } from "store/dashboard/types";
import { SectionDashBoardType } from "website/components/hooks/dashboard/usePersonnalisationDashboard";
import { useDroitsSegment } from "website/components/hooks/useDroitsSegment";
import { useFeatures } from "website/components/hooks/useFeatures";
import { AccompagnementTelephonique } from "website/components/molecules/AccompagnementTelephonique/AccompagnementTelephonique";
import { CarteSGAgenda } from "website/components/molecules/CarteSGAgenda/CarteSGAgenda";
import { TuileSolutionsEpargne } from "website/components/molecules/TuileSolutionsEpargne/TuileSolutionsEpargne";
import { Besoins } from "../CartesDonnees/Besoins";
import { SectionTemplate } from "../SectionTemplate/SectionTemplate";

interface SectionBesoinsProps {
  selectedRetirementAge?: DashboardAge;
}

const SectionBesoins: FunctionComponent<SectionBesoinsProps> = (
  props: SectionBesoinsProps
) => {
  const { selectedRetirementAge } =
    props;

  const { disponibiliteAppeler, disponibiliteWebCallback } = useFeatures();
  const { isWebCallbackAvailable } = useDroitsSegment();

  return (
    <SGSpace direction="vertical" size="lg">
      <SectionTemplate
        key="sectionTemplate"
        sectionType={SectionDashBoardType.BESOINS}
      >
        <Besoins
          key="besoins"
          selectedRetirementAge={selectedRetirementAge}
        />
        <TuileSolutionsEpargne
          key="solutionsEpargne"
          idTracking="clic-sur-card-nos-solutions-d-epargne"
          titreTaille={4}
        />
        <CarteSGAgenda key="SGAgenda" titreTaille={4} idTracking="clic-sur-contacter-un-conseiller-sg"/>
      </SectionTemplate>
      {(disponibiliteAppeler || disponibiliteWebCallback) &&
        isWebCallbackAvailable && <AccompagnementTelephonique key="tel" />}
    </SGSpace>
  );
};

export { SectionBesoins };
