import { FC, useEffect } from "react";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useForm,
} from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { SGInputNumber } from "sg-input";
import { SGLayout } from "sg-layout";
import { SGBox, SGSpace } from "sg-space";
import { SGTag } from "sg-tag";
import { SGTile } from "sg-tile";
import { SGPrice, SGText } from "sg-typo";
import { InfoBudget } from "store/budgetRetraite/types";
import { InfoTooltipAssu } from "website/components/molecules/InfoTooltipAssu/InfoTooltipAssu";

const INPUT_DEPENSE_NAME = "depense";

interface CadreSaisiePosteDepenseProps {
  posteDepense: InfoBudget;
  onChangeMontant: (posteDepense: string, valeur: number | undefined) => void;
}

const CadreSaisiePosteDepense: FC<CadreSaisiePosteDepenseProps> = (
  props: CadreSaisiePosteDepenseProps
) => {
  const { posteDepense, onChangeMontant } = props;

  const methods = useForm({
    criteriaMode: "all",
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const { control, setValue } = methods;

  useEffect(() => {
    setValue(INPUT_DEPENSE_NAME, posteDepense.montant, {
      shouldValidate: true,
    });
  }, [posteDepense.montant, setValue]);

  // Remet le champs à 0 s'il a été vidé
  const onBlurInput = (
    field: ControllerRenderProps<FieldValues, typeof INPUT_DEPENSE_NAME>,
    value?: number
  ) => {
    if (value === undefined) {
      field.onChange(0);
      onChangeMontant(posteDepense.posteDepense, 0);
    }
  };

  const onChangeInput = (
    field: ControllerRenderProps<FieldValues, typeof INPUT_DEPENSE_NAME>,
    value: number | ""
  ) => {
    const newValue = value === "" ? undefined : value;
    if (newValue !== posteDepense.montant) {
      field.onChange(newValue);
      onChangeMontant(posteDepense.posteDepense, newValue);
    }
  };

  return (
    <SGTile clickable disableautomargin>
      <SGSpace direction="vertical" size="sm" disableautomargin>
        <Controller
          key="saisie"
          control={control}
          name={INPUT_DEPENSE_NAME}
          defaultValue={posteDepense.montant}
          render={({ field }) => (
            <SGInputNumber
              disableautomargin
              label={
                <>
                  <SGTag tagcolor="45" disableautomargin>
                    <SGText whitespace="nowrap">
                      <FormattedMessage
                        id={`monObjectif.budget.tag.${posteDepense.posteDepense}`}
                      />
                    </SGText>
                  </SGTag>
                  {posteDepense.posteDepense !== "impots" && (
                    <InfoTooltipAssu
                      text={`monObjectif.budget.infobulle.${posteDepense.posteDepense}`}
                      placement="top"
                    />
                  )}
                </>
              }
              precision={0}
              value={posteDepense.montant}
              min={0}
              max={999_999}
              onChange={(value: number | "") => onChangeInput(field, value)}
              onBlur={() => onBlurInput(field, posteDepense.montant)}
            />
          )}
        />
        <SGLayout key="pourcentage" background="light">
          <SGBox padding={{ top: "sm", bottom: "sm", left: "sm", right: "sm" }}>
            <>
              <SGPrice
                size="l"
                unit="%"
                value={`${posteDepense.pourcentage}`}
              />
              <SGText>
                <FormattedMessage id="monObjectif.drawerPersonnaliser.budget" />
              </SGText>
            </>
          </SGBox>
        </SGLayout>
      </SGSpace>
    </SGTile>
  );
};

export { CadreSaisiePosteDepense };
