import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEpargneMaximale } from "store/budgetRetraite/budgetRetraiteSlice";
import { BudgetRetraiteState } from "store/budgetRetraite/types";
import { getSavingEffortOnce } from "store/savingPath/actions";
import {
  convertAgeTypeToWealthProfile,
  getEffortFromCache,
  SavingEffortRequest,
  SavingPathState,
} from "store/savingPath/types";
import { State } from "store/store";
import { roundDown10 } from "../../../utils/formatting/numberFormatter";
import { useMonPlanMontants } from "../monPlan/useMonPlanMontants";
import { useSelectedRetirementAge } from "./useSelectedRetirementAge";

/**
 * Détermine les montants maximums d'objectif et d'épargne pour la saisie.
 */
const useMontantsMax = () => {
  const dispatch = useDispatch();

  const { typeDepart, epargneMaximale }: BudgetRetraiteState = useSelector<
    State,
    BudgetRetraiteState
  >((state) => state.budgetRetraite);
  const { resultsEfforts, monthlyEffortError } = useSelector<
    State,
    SavingPathState
  >((state) => state.savingPath);

  const { selectedRetirementAge } = useSelectedRetirementAge();
  const { montantRevenusComplementaires, montantPension } = useMonPlanMontants(
    selectedRetirementAge,
    0 // Pour ne pas déclencher l'appel au moteur
  );

  const minObjectifInput = useMemo(
    () => (montantPension ?? 0) + (montantRevenusComplementaires ?? 0),
    [montantPension, montantRevenusComplementaires]
  );
  // Dernier salaire
  const maxObjectifInput = useMemo(
    () => selectedRetirementAge?.lastMonthlyIncome ?? 0,
    [selectedRetirementAge?.lastMonthlyIncome]
  );
  // Pension + Patrimoine + 15%
  const objectifDefaut = useMemo(
    () => roundDown10(1.15 * minObjectifInput),
    [minObjectifInput]
  );

  const minEpargneInput = 0;
  // Epargne générant l'objectif max
  const maxEpargneInput = epargneMaximale ?? 0;

  const epargneMaximaleFetched = epargneMaximale !== undefined;

  const effortEpargne = useMemo(() => {
    if (!selectedRetirementAge?.type) {
      return undefined;
    }

    return getEffortFromCache(
      resultsEfforts,
      selectedRetirementAge.type,
      maxObjectifInput
    );
  }, [resultsEfforts, selectedRetirementAge?.type, maxObjectifInput]);

  useEffect(() => {
    if (selectedRetirementAge?.type && effortEpargne === undefined) {
      const req: SavingEffortRequest = {
        profile: convertAgeTypeToWealthProfile(selectedRetirementAge.type),
        monthlyIncomeObjective: maxObjectifInput,
      };
      dispatch(getSavingEffortOnce(req));
    }
  }, [dispatch, selectedRetirementAge?.type, effortEpargne, maxObjectifInput]);

  // Récupération de l'objectif ou du revenu via le moteur
  useEffect(() => {
    const effortArrondi = roundDown10(effortEpargne ?? 0);
    if (
      selectedRetirementAge?.type !== typeDepart &&
      (effortEpargne !== undefined || monthlyEffortError) &&
      effortArrondi !== epargneMaximale
    ) {
      dispatch(
        setEpargneMaximale({
          typeDepart: selectedRetirementAge?.type,
          epargneMaximale: effortArrondi,
        })
      );
    }
  }, [
    dispatch,
    effortEpargne,
    epargneMaximale,
    selectedRetirementAge?.type,
    typeDepart,
    monthlyEffortError,
  ]);

  return {
    minObjectifInput,
    maxObjectifInput,
    objectifDefaut,
    minEpargneInput,
    maxEpargneInput,
    epargneMaximaleFetched,
  };
};

export { useMontantsMax };
