import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGLink } from "sg-link";
import { useMediaQuery } from "sg-media-query";
import { SGSkeleton } from "sg-skeleton";
import { SGBox, SGSpace } from "sg-space";
import { SGTag } from "sg-tag";
import { SGPrice, SGText } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export interface RevenusElementListe {
  tag: string;
  couleur: string;
  suffixe?: string;
  montant?: number;
  showMontant: boolean;
  lien?: string;
  pageField3Tracking?: string;
  showSkeleton: boolean;
}

interface LignesRevenusProps {
  dataSource: RevenusElementListe[];
}

export const LignesRevenus: FC<LignesRevenusProps> = ({ dataSource }) => {
  const { trackClick } = useTracking();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  return (
    <SGSpace key="space" direction="vertical" disableautomargin>
      {dataSource.map((item: RevenusElementListe) => (
        <SGGridRow key={`item${item.tag}`} align="middle" gutter={[0, 0]}>
          <SGGridCol flex="0 0 0%">
            <SGTag tagcolor={item.couleur}>
              <SGText whitespace="nowrap">{item.tag}</SGText>
            </SGTag>
          </SGGridCol>
          <SGGridCol flex="0 0 0%">
            <SGText size="xs">
              {item.suffixe ?? (
                <FormattedMessage id="dashboard.carteDonnee.tag.suffixe" />
              )}
            </SGText>
          </SGGridCol>
          <SGGridCol flex="1 0 0%" textalign="right">
            {item.showSkeleton && (
              <SGBox span={1} inline>
                <SGSkeleton title={false} paragraph={{ rows: 1 }} />
              </SGBox>
            )}
            {!item.showSkeleton && item.showMontant && (
              <SGPrice
                size={isPhone ? "l" : "m"}
                value={
                  item.montant === undefined
                    ? "-"
                    : item.montant.toLocaleString("fr-FR")
                }
              />
            )}
            {!item.showSkeleton && !item.showMontant && (
              <SGLink
                type="link"
                href={`#${item.lien}`}
                onClick={() => {
                  if (item.pageField3Tracking) {
                    trackClick(
                      `clic-sur-a-completer-detail-de-mes-revenus-a-la-retraite`,
                      { page_field_3: item.pageField3Tracking }
                    );
                  }
                }}
              >
                <SGText size={isPhone ? "l" : "m"}>à compléter</SGText>
              </SGLink>
            )}
          </SGGridCol>
        </SGGridRow>
      ))}
    </SGSpace>
  );
};
