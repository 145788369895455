import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiGet } from "store/apiCaller";
import { DashboardAge } from "store/dashboard/types";
import { onDefaultError, safe } from "store/error/utils";
import { convertAgeTypeToWealthProfile } from "store/savingPath/types";
import { maSimulationImpotFetched } from "./maSimulationImpotSlice";
import {
  FETCH_MA_SIMULATION,
  recupererImpotDansResultat,
  SimulationResult,
} from "./types";

export function* watcherSaga() {
  yield takeLeading(
    FETCH_MA_SIMULATION,
    safe(onDefaultError, handleFetchMaSimulationImpot)
  );
}

function* handleFetchMaSimulationImpot(action: PayloadAction<DashboardAge>) {
  const wealthProfile = convertAgeTypeToWealthProfile(action.payload.type);
  const payload: AxiosResponse<SimulationResult> = yield call(
    apiGet,
    `/wealth/api/simulation/me?profile=${wealthProfile}`
  );

  const impot = recupererImpotDansResultat(
    payload.data,
    action.payload.retirementYear
  );
  yield put(maSimulationImpotFetched(impot));
}
