import { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGAvenirStyledCompte } from "sg-icon-pack-base";
import { SGLink } from "sg-link";
import { SectionDashBoardType } from "website/components/hooks/dashboard/usePersonnalisationDashboard";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { TuileCliquableLienPersonalise } from "website/components/molecules/TuileCliquable/TuileCliquableLienPersonalise";
import { SIMULATEUR_FLASH } from "website/utils/privateRoutes";
import { AccesSimulateurReforme } from "../../AccesSimulateurReforme/AccesSimulateurReforme";
import { AccesSyntheseRetraite } from "../AccesSyntheseRetraite/AccesSyntheseRetraite";
import { SectionTemplate } from "../SectionTemplate/SectionTemplate";

const SectionReformeEtSynthese: FunctionComponent = () => {
  const intl = useIntl();
  const { disponibiliteSimulateurFlash, simulateurAvantApres } = useFeatures();
  const { trackClick } = useTracking();

  return (
    <SectionTemplate
      sectionType={SectionDashBoardType.PENSION}
      showHeader={false}
    >
      {disponibiliteSimulateurFlash && (
        <TuileCliquableLienPersonalise
          width={null}
          icone={<SGAvenirStyledCompte />}
          iconeTaille="s"
          titre={intl.formatMessage({
            id: "sectionReformeEtSynthese.carte.modifierProfil.titre",
          })}
          texte={intl.formatMessage({
            id: "sectionReformeEtSynthese.carte.modifierProfil.texte",
          })}
          lien={
            <SGLink
              href={`#${SIMULATEUR_FLASH}`}
              onClick={() => trackClick("clic-sur-modifier-mes-informations")}
              ariaLabel="Modifier vos informations"
            >
              <FormattedMessage id="sectionReformeEtSynthese.carte.modifierProfil.lien" />
            </SGLink>
          }
        />
      )}
      {simulateurAvantApres && (
        <AccesSimulateurReforme key="accesSimulateurReforme" />
      )}
      <AccesSyntheseRetraite key="accesSyntheseRetraite" />
    </SectionTemplate>
  );
};

export { SectionReformeEtSynthese };
