import { PayloadAction } from "@reduxjs/toolkit";
import { makeAction } from "store/utils";
import {
  DELETE_POSTES_DEPENSES,
  FETCH_POSTES_DEPENSES,
  POST_POSTES_DEPENSES,
  PostesDepensesDTO,
} from "./types";

/**
 * Appelle le backpfr pour récupérer les postes de dépenses
 */
export const fetchPostesDepenses = (): PayloadAction<null> =>
  makeAction(FETCH_POSTES_DEPENSES, null);

/**
 * Appelle le backpfr pour mettre à jour les postes de dépenses
 */
export const postPostesDepenses = (
  data: PostesDepensesDTO
): PayloadAction<PostesDepensesDTO> => makeAction(POST_POSTES_DEPENSES, data);

/**
 * Supprime les postes de dépenses en BDD
 */
export const deletePostesDepenses = (): PayloadAction<null> =>
  makeAction(DELETE_POSTES_DEPENSES, null);
