import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { SGAvenirStrokedFinancement } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { BudgetRetraiteState } from "store/budgetRetraite/types";
import { State } from "store/store";
import { useRevenuAFinancer } from "website/components/hooks/useRevenuAFinancer";
import { CadreInfoGris } from "website/components/molecules/CadreInfoGris/CadreInfoGris";
import { getTextStrong } from "website/utils/formatting/ValuesFormattedMessage";
import { OngletEffortEpargne } from "../Dashboard/EffortEpargne/EffortEpargne";

interface LignesRevenusProps {
  ongletDefaut: OngletEffortEpargne;
}

export const CadreInfoEpargne: FC<LignesRevenusProps> = ({ ongletDefaut }) => {
  const { objectifRevenu, montantEpargne }: BudgetRetraiteState = useSelector<
    State,
    BudgetRetraiteState
  >((state) => state.budgetRetraite);
  const { revenuAFinancer } = useRevenuAFinancer();

  if (revenuAFinancer === 0) {
    return (
      <CadreInfoGris icone={<SGAvenirStrokedFinancement />}>
        <SGText>
          <FormattedMessage id="monObjectif.objectif.atteint" />
        </SGText>
      </CadreInfoGris>
    );
  }

  return (
    <>
      <SGBox padding={{ bottom: "xs" }}>
        <SGTitle level={3} visuallevel={5} textalign="left">
          <FormattedMessage id={`monObjectif.${ongletDefaut}.sousTitre`} />
        </SGTitle>
      </SGBox>
      <CadreInfoGris icone={<SGAvenirStrokedFinancement />}>
        <SGText>
          <FormattedMessage
            id={`monObjectif.effortEpargne.${ongletDefaut}.cadreInfoEpargne`}
            values={{
              objectifRevenu: objectifRevenu?.toLocaleString("fr-FR"),
              montantAEpargner: montantEpargne?.toLocaleString("fr-FR"),
              montantGenere: revenuAFinancer?.toLocaleString("fr-FR"),
              b: getTextStrong,
            }}
          />
        </SGText>
      </CadreInfoGris>
    </>
  );
};
