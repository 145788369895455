import { ReactNodeLike } from "prop-types";
import React, { FunctionComponent } from "react";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGTile } from "sg-tile";

interface CadreInfoGrisProps {
  icone: ReactNodeLike;
  disableautomargin?: boolean;
}

const CadreInfoGris: FunctionComponent<CadreInfoGrisProps> = (
  props: React.PropsWithChildren<CadreInfoGrisProps>
) => {
  const { icone, disableautomargin, children } = props;

  return (
    <SGTile background="Sec_Sg2" disableautomargin={disableautomargin}>
      <SGGridRow align="middle">
        <SGGridCol flex="0 0 0%">
          <SGIcon key="icone" component={icone} currentcolor />
        </SGGridCol>
        <SGGridCol flex="1 0 0%">{children}</SGGridCol>
      </SGGridRow>
    </SGTile>
  );
};

export { CadreInfoGris };
