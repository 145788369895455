import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStrokedCheck } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGBlock, SGText } from "sg-typo";
import { MarginBox } from "../composantsSGC/types";

export const getParagraph = (word: string) => (
  <SGBlock component="p">{word}</SGBlock>
);

export const getBoxMargin = (word: string, margin: MarginBox) => (
  <SGBox margin={margin}>{word}</SGBox>
);

export const getUl = (word: string) => (
  <SGBlock component="ul" disableautomargin>
    {word}
  </SGBlock>
);

export const getLi = (word: string) => <li>{word}</li>;

export const getUnderline = (word: string) => <SGText underline>{word}</SGText>;

export const getTextStrong = (word: string) => <SGText strong>{word}</SGText>;

export const getTextWeight = (word: string, weight: "600") => (
  <SGText weight={weight}>{word}</SGText>
);

export const getTextSup = (word: string) => <sup>{word}</sup>;

export const getChecklistItem = (word: string) => (
  <SGGridRow gutter={["sm", 0]} align="middle">
    <SGGridCol flex="0 0 0%">
      <SGIcon key="icone" component={<SGAvenirStrokedCheck />} />
    </SGGridCol>
    <SGGridCol flex="1 0 0%">
      <SGText key="texte">{word}</SGText>
    </SGGridCol>
  </SGGridRow>
);
