import { FunctionComponent } from "react";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { CarteCliquable } from "website/components/molecules/CarteCliquable/CarteCliquable";
import { SYNTHESE_RETRAITE } from "website/utils/privateRoutes";

export const AccesSyntheseRetraite: FunctionComponent = () => {
  const { trackClick } = useTracking();

  const handleOnClick = () => {
    trackClick("clic-sur-card-ma-synthese");
  };

  return (
    <CarteCliquable
      src="images/dashboard/syntheseRetraite.jpg"
      alt="La Synthese Retraite"
      idTexte="sectionReformeEtSynthese.carte.syntheseRetraite.texte"
      idTitre="sectionReformeEtSynthese.carte.syntheseRetraite.titre"
      onClick={handleOnClick}
      lien={`#${SYNTHESE_RETRAITE}`}
    />
  );
};
