import { FETCH_EFFORTS, FETCH_SAVINGPATH_FROM_EFFORT, FETCH_SAVINGPATH_FROM_OBJECTIVE, FETCH_SAVINGPATH_FROM_OBJECTIVE_ONCE, FETCH_SAVINGPATHS_FROM_EFFORT, FETCH_SAVINGPATHS_FROM_OBJECTIVE, MonthlyIncomeRequest, MonthlyIncomesRequest, SavingEffortRequest, SavingEffortsRequest, SAVINGPATH_ERROR_RESET, SAVINGPATH_RESULTS_RESET } from "./types";

export const getSavingEffortOnce = (data: SavingEffortRequest) => ({
   type: FETCH_SAVINGPATH_FROM_OBJECTIVE_ONCE,
   payload: data,
});
export const getSavingEffort = (data: SavingEffortRequest) => ({
   type: FETCH_SAVINGPATH_FROM_OBJECTIVE,
   payload: data,
});
export const getSavingEfforts = (data: SavingEffortsRequest) => ({
   type: FETCH_SAVINGPATHS_FROM_OBJECTIVE,
   payload: data,
});
export const resetResultsEfforts = () => ({
   type: SAVINGPATH_RESULTS_RESET,
});
export const resetErrorEfforts = () => ({
   type: SAVINGPATH_ERROR_RESET,
});
export const getEffortsState = (data: SavingEffortsRequest) => ({
   type: FETCH_EFFORTS,
   payload: data,
});
export const getMonthlyIncome = (data: MonthlyIncomeRequest) => ({
   type: FETCH_SAVINGPATH_FROM_EFFORT,
   payload: data,
});

export const getMonthlyIncomes = (data: MonthlyIncomesRequest) => ({
   type: FETCH_SAVINGPATHS_FROM_EFFORT,
   payload: data,
});
