import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MaSimulationImpotState } from "./types";

const initialState: MaSimulationImpotState = {
  hasFetched: false,
};

export const maSimulationImpotSlice = createSlice({
  name: "maSimulationImpot",
  initialState,
  reducers: {
    maSimulationImpotFetched: (
      currentState,
      action: PayloadAction<number>
    ): MaSimulationImpotState => ({
      ...currentState,
      impot: action.payload,
      hasFetched: true,
    }),
    resetMaSimulationImpot: (): MaSimulationImpotState => ({
      ...initialState,
    }),
  },
});

export const { maSimulationImpotFetched, resetMaSimulationImpot } =
  maSimulationImpotSlice.actions;

export default maSimulationImpotSlice.reducer;
