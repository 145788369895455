import { PayloadAction } from "@reduxjs/toolkit";
import { DashboardAge } from "store/dashboard/types";
import { FETCH_MA_SIMULATION } from "./types";

export const callMaSimulationImpot = (
  params: DashboardAge
): PayloadAction<DashboardAge> => ({
  type: FETCH_MA_SIMULATION,
  payload: params,
});
