import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BudgetRetraiteState,
  InfoBudget,
  PosteDepense,
  PostesDepensesDTO,
} from "store/budgetRetraite/types";
import { majBornesPostes } from "store/budgetRetraite/utils";
import { callMaSimulationImpot } from "store/maSimulationImpot/actions";
import { MaSimulationImpotState } from "store/maSimulationImpot/types";
import { State } from "../../../store/store";
import { useSelectedRetirementAge } from "./dashboard/useSelectedRetirementAge";

interface ParamPosteDepense {
  posteDepense: PosteDepense;
  pourcentage: number;
}

const posteDepenseToDTOKey: { [_key: string]: keyof PostesDepensesDTO } = {
  logement: "montantLogement",
  quotidien: "montantQuotidien",
  transports: "montantTransports",
  loisirs: "montantLoisirs",
  sante: "montantSante",
  impots: "montantImpots",
};

const PARAM_POSTES_DEPENSES: ParamPosteDepense[] = [
  {
    posteDepense: PosteDepense.logement,
    pourcentage: 28,
  },
  {
    posteDepense: PosteDepense.quotidien,
    pourcentage: 25,
  },
  {
    posteDepense: PosteDepense.transports,
    pourcentage: 17,
  },
  {
    posteDepense: PosteDepense.loisirs,
    pourcentage: 21,
  },
  {
    posteDepense: PosteDepense.sante,
    pourcentage: 9,
  },
];

const useBudgetRetraite = () => {
  const dispatch = useDispatch();

  const maSimulationImpotState = useSelector<State, MaSimulationImpotState>(
    (state) => state.maSimulationImpot
  );

  const {
    objectifRevenu,
    saisieEnErreur,
    postesDepenses,
  }: BudgetRetraiteState = useSelector<State, BudgetRetraiteState>(
    (state) => state.budgetRetraite
  );
  const { selectedRetirementAge } = useSelectedRetirementAge();

  useEffect(() => {
    if (!maSimulationImpotState.hasFetched && selectedRetirementAge) {
      dispatch(callMaSimulationImpot(selectedRetirementAge));
    }
  }, [dispatch, maSimulationImpotState.hasFetched, selectedRetirementAge]);

  const infosBudgetDefaut: InfoBudget[] = useMemo(
    () =>
      PARAM_POSTES_DEPENSES.map((poste) => ({
        posteDepense: poste.posteDepense,
        pourcentage: 0,
        montant: undefined,
      })),
    []
  );

  /**
   * Infos venant de la BDD (ou en mémoire) ou undefined
   */
  const infosBudgetPersistees: InfoBudget[] | undefined = useMemo(() => {
    if (!postesDepenses) {
      return undefined;
    }

    const postesSansPourcentage: InfoBudget[] = [
      ...PARAM_POSTES_DEPENSES,
      {
        posteDepense: PosteDepense.impots,
      },
    ].map((param) => ({
      posteDepense: param.posteDepense,
      pourcentage: 0,
      montant: postesDepenses[posteDepenseToDTOKey[param.posteDepense]],
    }));

    const totalDepenses = postesSansPourcentage.reduce(
      (prev, curr) => prev + (curr.montant ?? 0),
      0
    );

    return postesSansPourcentage.map((poste) => ({
      ...poste,
      pourcentage: Math.round(((poste.montant ?? 0) * 100) / totalDepenses),
    }));
  }, [postesDepenses]);

  const infosBudget: InfoBudget[] = useMemo(() => {
    if (infosBudgetPersistees) {
      return infosBudgetPersistees;
    }

    if (
      !maSimulationImpotState.hasFetched ||
      saisieEnErreur ||
      !objectifRevenu // Pour typescript
    ) {
      return [
        ...infosBudgetDefaut,
        {
          posteDepense: PosteDepense.impots,
          pourcentage: 0,
        },
      ];
    }

    const totalHorsImpot = objectifRevenu - (maSimulationImpotState.impot ?? 0);
    const postesInitAvecImpot: InfoBudget[] = PARAM_POSTES_DEPENSES.map(
      (poste) => ({
        posteDepense: poste.posteDepense,
        pourcentage: poste.pourcentage,
        montant: Math.round((totalHorsImpot * poste.pourcentage) / 100),
      })
    );
    postesInitAvecImpot.push({
      posteDepense: PosteDepense.impots,
      pourcentage: 0,
      montant: maSimulationImpotState.impot ?? 0,
    });

    const postesCalcules: InfoBudget[] = postesInitAvecImpot.map((poste) => ({
      posteDepense: poste.posteDepense,
      pourcentage: Math.round(((poste.montant ?? 0) * 100) / objectifRevenu),
      montant: poste.montant,
    }));

    majBornesPostes(postesCalcules, objectifRevenu);

    return postesCalcules;
  }, [
    maSimulationImpotState.hasFetched,
    saisieEnErreur,
    objectifRevenu,
    maSimulationImpotState.impot,
    infosBudgetDefaut,
    infosBudgetPersistees,
  ]);

  return { infosBudget };
};

export { useBudgetRetraite };
