import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { SGAvenirStrokedFinancement } from "sg-icon-pack-base";
import { SGPriceCommercial, SGText } from "sg-typo";
import { DashboardAge } from "store/dashboard/types";
import { SavingPathState } from "store/savingPath/types";
import { State } from "store/store";
import { useMonPlanMontants } from "website/components/hooks/monPlan/useMonPlanMontants";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useParcoursEpargne } from "website/components/hooks/useParcoursEpargne";
import { BESOIN_EPARGNE } from "website/utils/privateRoutes";
import { CadreInfoSup } from "../CadreInfoSup";
import { CarteDonnee, NomCarteEnum, TypeContenuCarteEnum } from "./CarteDonnee";

interface BesoinsProps {
  selectedRetirementAge?: DashboardAge;
}

const Besoins: FC<BesoinsProps> = (props: BesoinsProps) => {
  const { selectedRetirementAge } = props;

  const { hasObjectif } = useParcoursEpargne();
  const { trackClick } = useTracking();

  const { montantEffortEpargne, montantRevenusAFinancer } = useMonPlanMontants(
    selectedRetirementAge
  );
  const { monthlyEffortError } = useSelector<State, SavingPathState>(
    (state) => state.savingPath
  );

  const handleOnClickConsulter = () => {
    trackClick("clic-sur-consulter-besoins-et-epargne");
  };

  const handleOnClickEstimer = () => {
    trackClick("clic-sur-estimer-besoins-et-epargne");
  };

  return hasObjectif ? (
    <CarteDonnee
      nom={NomCarteEnum.BESOINS}
      tagColor="11"
      isActif={!monthlyEffortError}
      typeContenu={TypeContenuCarteEnum.MONTANT}
      montant={montantRevenusAFinancer ?? 0}
      lien={`#${BESOIN_EPARGNE}`}
      onClick={handleOnClickConsulter}
      disponibiliteFeatureFlipping
      showSkeleton={montantEffortEpargne === undefined && !monthlyEffortError}
    >
      {monthlyEffortError ? (
        <SGText key="erreur" color="error">
          <FormattedMessage id="dashboard.carteDonnee.besoins.erreur" />
        </SGText>
      ) : (
        <CadreInfoSup
          key="cadre-info-sup"
          icone={<SGAvenirStrokedFinancement />}
        >
          <SGText key="titre-besoins-definis" weight="600">
            <FormattedMessage id="dashboard.cadreGris.carteDonnee.besoins.titre" />
          </SGText>
          <SGPriceCommercial
            key="prix"
            value={montantEffortEpargne?.toLocaleString("fr-FR") || "--"}
            size="l"
            layout="l2"
            term="mois (net)"
          />
        </CadreInfoSup>
      )}
    </CarteDonnee>
  ) : (
    <CarteDonnee
      nom={NomCarteEnum.BESOINS}
      tagColor="11"
      typeContenu={TypeContenuCarteEnum.DESCRIPTION}
      lien={`#${BESOIN_EPARGNE}`}
      onClick={handleOnClickEstimer}
      disponibiliteFeatureFlipping
    />
  );
};

export { Besoins };
