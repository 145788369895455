import { PropsWithChildren, ReactNode } from "react";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFleche } from "sg-icon-pack-base";
import { SGLink } from "sg-link";
import { TuileCliquableLienPersonalise } from "./TuileCliquableLienPersonalise";

interface TuileCliquableProps {
  icone?: JSX.Element;
  /**
   * Pour les pictos Avenir stroked : s, m, l
   * Pour les pictos Avenir styled : s, m
   */
  iconeTaille?: string;
  titre?: string | ReactNode;
  titreTaille?: number;
  texte?: string | ReactNode;
  texteTaille?: "m" | "s";
  onClick: () => void;
  lien?: string;
  accessible?: boolean;
  disableautomargin?: boolean;
  width?: "auto" | null;
  ariaLabel?: string;
  alignchildren?: "center";
  titleLevel?: number;
}

export const TuileCliquable = ({
  icone,
  iconeTaille = "l",
  titre,
  titreTaille,
  texte,
  texteTaille = "s",
  onClick,
  lien,
  accessible = true,
  disableautomargin = false,
  width,
  ariaLabel,
  alignchildren,
  children,
  titleLevel = 3,
}: PropsWithChildren<TuileCliquableProps>) => (
  <TuileCliquableLienPersonalise
    accessible={accessible}
    disableautomargin={disableautomargin}
    icone={icone}
    iconeTaille={iconeTaille}
    titre={titre}
    titreTaille={titreTaille}
    titleLevel={titleLevel}
    texte={texte}
    texteTaille={texteTaille}
    width={width}
    alignchildren={alignchildren}
    lien={
      lien ? (
        <SGLink
          href={lien}
          icon={
            accessible ? <SGIcon component={<SGAvenirNavFleche />} /> : null
          }
          type="cta"
          onClick={onClick}
          ariaLabel={ariaLabel || `Rediriger vers ${titre}`}
        />
      ) : (
        <SGButton
          icon={
            accessible ? <SGIcon component={<SGAvenirNavFleche />} /> : null
          }
          type="icon"
          onClick={onClick}
          ariaLabel={ariaLabel || `Rediriger vers ${titre}`}
        />
      )
    }
  >
    {children}
  </TuileCliquableLienPersonalise>
);
