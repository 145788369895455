import { FC, useMemo } from "react";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGProgress } from "sg-progress";
import { SGSkeleton } from "sg-skeleton";
import { SGSpace } from "sg-space";
import { SGTile } from "sg-tile";
import { SGPrice, SGPriceCommercial, SGText } from "sg-typo";
import { SectionDashBoardType } from "website/components/hooks/dashboard/usePersonnalisationDashboard";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { useMonPlanMontants } from "website/components/hooks/monPlan/useMonPlanMontants";
import { useParcoursEpargne } from "website/components/hooks/useParcoursEpargne";
import { useParcoursPatrimoine } from "website/components/hooks/useParcoursPatrimoine";
import {
  BESOIN_EPARGNE,
  PARCOURS_REVENUS_COMPLEMENTAIRES,
} from "website/utils/privateRoutes";
import { SectionTemplate } from "../SectionTemplate/SectionTemplate";
import { LignesRevenus, RevenusElementListe } from "./LignesRevenus";

export const Revenus: FC = () => {
  const { selectedRetirementAge } = useSelectedRetirementAge();
  const { isAssetsChecked, isParcoursPatrimoineDone } = useParcoursPatrimoine();
  const { hasObjectif } = useParcoursEpargne();

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  const {
    montantRevenusComplementaires,
    montantRevenusAFinancer,
    montantPension,
    montantTotal,
    hasFetchedDashboardState,
    hasFetchedAssetIncomesState,
  } = useMonPlanMontants(selectedRetirementAge);

  const dernierSalaire = selectedRetirementAge?.lastMonthlyIncome;

  const pourcentage = useMemo<number>(
    () => Math.round(((montantTotal ?? 0) * 100) / (dernierSalaire ?? 0)),
    [dernierSalaire, montantTotal]
  );

  const showSkeleton = !(
    hasFetchedAssetIncomesState && hasFetchedDashboardState
  );

  const dataSource: RevenusElementListe[] = useMemo(
    () => [
      {
        tag: "ma pension",
        couleur: "26",
        montant: montantPension || 0,
        showMontant: true,
        showSkeleton: !hasFetchedDashboardState,
      },
      {
        tag: "mon patrimoine",
        couleur: "36",
        montant: montantRevenusComplementaires || 0,
        showMontant: isParcoursPatrimoineDone && isAssetsChecked,
        lien: PARCOURS_REVENUS_COMPLEMENTAIRES,
        pageField3Tracking: "mon-patrimoine",
        showSkeleton: !hasFetchedAssetIncomesState,
      },
      {
        tag: "mon épargne future",
        couleur: "11",
        montant: montantRevenusAFinancer || 0,
        showMontant: hasObjectif,
        lien: BESOIN_EPARGNE,
        pageField3Tracking: "mon-epargne",
        showSkeleton: !(
          hasFetchedDashboardState && hasFetchedAssetIncomesState
        ),
      },
    ],
    [
      hasFetchedDashboardState,
      hasFetchedAssetIncomesState,
      montantPension,
      montantRevenusComplementaires,
      montantRevenusAFinancer,
      isParcoursPatrimoineDone,
      isAssetsChecked,
      hasObjectif,
    ]
  );

  return (
    <SectionTemplate sectionType={SectionDashBoardType.REVENUS}>
      <SGTile key="tile" clickable>
        <SGSpace direction="vertical" disableautomargin size="md">
          <SGProgress
            arialabel={`Barre de progression représentant le montant total de mes revenus à retraite ${montantTotal} € par rapport à l’estimation de mes revenus en fin de carrière ${dernierSalaire} €.`}
            key="progress"
            context="money"
            showInfo={false}
            size="lg"
            percent={pourcentage}
            status="warning"
            valueMin="0"
            valueMax={`${dernierSalaire}`}
            header={
              <SGGridRow>
                <SGGridCol span={8}>
                  <SGText
                    weight="600"
                    size={isPhone ? "m" : "s"}
                    whitespace="pre-line"
                  >
                    Mes revenus à la retraite{"\n"}
                    <SGText size={isPhone ? "s" : "xs"} weight="400">
                      net/mois
                    </SGText>
                  </SGText>
                </SGGridCol>
                <SGGridCol span={4} textalign="right">
                  <SGPrice
                    size={isPhone ? "l" : "m"}
                    value={`${montantTotal?.toLocaleString("fr-FR") || 0}`}
                  />
                </SGGridCol>
              </SGGridRow>
            }
          />
          {pourcentage < 100 && (
            <SGTile background="Sec_Sg2" key="tile">
              {showSkeleton ? (
                <SGSkeleton title={false} paragraph={{ rows: 3 }} />
              ) : (
                <SGGridRow align="middle">
                  <SGGridCol flex="1 0 0%">
                    <SGText size="xs" weight="600">
                      Écart estimé entre mes revenus à la retraite et
                      l'estimation de mes revenus professionnels en fin de
                      carrière
                      {` ${
                        dernierSalaire?.toLocaleString("fr-FR") || 0
                      }${" "}€`}
                    </SGText>
                  </SGGridCol>
                  <SGGridCol flex="initial">
                    <SGPriceCommercial
                      size="l"
                      unit="%"
                      value={`${pourcentage - 100}`}
                    />
                  </SGGridCol>
                </SGGridRow>
              )}
            </SGTile>
          )}
          <SGText key="text" titlerender size={isPhone ? "l" : "m"}>
            Détail de mes revenus à la retraite
          </SGText>

          <LignesRevenus key="lignes-revenus" dataSource={dataSource} />
        </SGSpace>
      </SGTile>
    </SectionTemplate>
  );
};
