export interface MaSimulationImpotState {
  impot?: number;
  hasFetched: boolean;
}

interface IncomeExpenseParAge {
  /**
   * Age
   */
  a: string;
  /**
   * Dépense (peut être négative)
   */
  d: number;
}

interface IncomeExpenseParAnnee {
  [__key: string]: IncomeExpenseParAge;
}

interface IncomeExpensesParCategorie {
  TAX: IncomeExpenseParAnnee;
  // On ignore les
}

export interface SimulationResult {
  incomeExpenses: IncomeExpensesParCategorie;
}

export const FETCH_MA_SIMULATION = "FETCH_MA_SIMULATION";

export function recupererImpotDansResultat(
  resultat: SimulationResult,
  anneeDepartRetraite: number
): number {
  const depenseParAnnee = resultat.incomeExpenses.TAX[anneeDepartRetraite];
  if (!depenseParAnnee) {
    throw new Error("Impot introuvable");
  }

  // On retourne le mensuel et on passe en positif
  return Math.abs(Math.floor(depenseParAnnee.d / -12));
}
