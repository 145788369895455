import { PayloadAction } from '@reduxjs/toolkit';
import { Task } from 'redux-saga';
import { take, fork, cancel } from 'redux-saga/effects'

interface CacheTask {
    lastTask: {[_key: string]: Task | null};
    lastParam: {[_key: string]: any };
}
/**
 * Takes the first action with same parameters.
 * Cancels the previous task if the named parameter in the payload changed.
 *
 * @param patternOrChannel Channel
 * @param saga Saga
 * @param args Args The first arg is the key to test in the payload
 */
export const takeNewLatest = (patternOrChannel: any, saga: (...args: any) => void, ...args: any) => fork(function* _() {
    const [key, ...newArgs]: [string, ...any] = args;
    const cache: CacheTask = {
        lastTask: {},
        lastParam: {}
    }
    while (true) {
        const action: PayloadAction<any> = yield take(patternOrChannel);
        const lastParam = cache.lastParam[patternOrChannel];
        const lastTask = cache.lastTask[patternOrChannel];
        if (action.payload[key] !== lastParam || !lastTask?.isRunning()) {
            if (lastTask && lastTask.isRunning()) {
                yield cancel(lastTask); // cancel is no-op if the task has already terminated
            }
            cache.lastParam[patternOrChannel] = action.payload[key];
            cache.lastTask[patternOrChannel] = yield fork(saga, ...newArgs.concat(action));
        }
    }
});
