import { FunctionComponent, ReactNode, useEffect } from "react";
import {
  Controller,
  ControllerRenderProps,
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  FormProvider,
  Merge,
  useForm,
} from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SGInputNumber } from "sg-input";
import { SGBox } from "sg-space";
import { SGBlock } from "sg-typo";
import { SavingPathState } from "store/savingPath/types";
import { State } from "store/store";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { roundDown10 } from "website/utils/formatting/numberFormatter";

function getErreurEditable(
  editable: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined,
  minMessage?: string,
  maxMessage?: string
) {
  if (editable?.type === "min") {
    return minMessage;
  }
  if (editable?.type === "max") {
    return maxMessage;
  }

  return undefined;
}

interface EffortEpargneTabProps {
  currentTab: "objectif" | "epargne";
  editableValue?: number;
  setEditableValue: (value?: number) => void;
  texteSousInput?: ReactNode;
  minValueInput: number;
  minMessage?: string;
  maxValueInput: number;
  maxMessage?: string;
  setHasChangedValue: (val: boolean) => void;
}

const EffortEpargneTab: FunctionComponent<EffortEpargneTabProps> = (
  props: EffortEpargneTabProps
) => {
  const {
    currentTab,
    editableValue,
    setEditableValue,
    texteSousInput,
    minValueInput,
    minMessage,
    maxValueInput,
    maxMessage,
    setHasChangedValue,
  } = props;

  const intl = useIntl();

  const methods = useForm({
    criteriaMode: "all",
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const {
    control,
    formState: { errors },
    setValue,
  } = methods;

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);
  const { monthlyEffortError } = useSelector<State, SavingPathState>(
    (state) => state.savingPath
  );

  const messageErreur = getErreurEditable(
    errors.editable,
    minMessage,
    maxMessage
  );

  /**
   * Gestion de l'arrondi et préviens le champs d'une sortie (pour les erreurs)
   * @param field Champs de saisie
   */
  const onBlurInput = (
    field: ControllerRenderProps<FieldValues, "editable">
  ) => {
    field.onBlur();
    if (editableValue !== undefined) {
      onChangeInput(field, roundDown10(editableValue));
    }
  };

  const onChangeInput = (
    field: ControllerRenderProps<FieldValues, "editable">,
    value: number | ""
  ) => {
    const newValue = value === "" ? undefined : value;
    field.onChange(newValue);
    setEditableValue(newValue);
    setHasChangedValue(true);
  };

  useEffect(() => {
    setValue("editable", editableValue, {
      shouldValidate: true,
    });
  }, [editableValue, setValue]);

  return (
    <FormProvider {...methods}>
      <SGBlock disableautomargin component="form" onSubmit={() => false}>
        <SGBox span={4} margin={{ top: "sm", bottom: "xxs" }}>
          <>
            <Controller
              control={control}
              name="editable"
              rules={{
                min: minValueInput,
                max: maxValueInput,
              }}
              defaultValue={editableValue}
              render={({ field }) => (
                <SGInputNumber
                  label={
                    <FormattedMessage
                      id={`monObjectif.effortEpargne.${currentTab}.label`}
                    />
                  }
                  placeholder={intl.formatMessage({
                    id: `monObjectif.${currentTab}.placeholder`,
                  })}
                  precision={0}
                  value={editableValue}
                  min={0}
                  max={999_999}
                  onBlur={() => onBlurInput(field)}
                  onChange={(value: number | "") => onChangeInput(field, value)}
                  disabled={!possedeDroitsActionUtilisateur(supervisionState)}
                  status={
                    (editableValue !== undefined && errors.editable?.type) ||
                    monthlyEffortError
                      ? "error"
                      : "default"
                  }
                  validate={
                    monthlyEffortError
                      ? intl.formatMessage({ id: "monObjectif.erreurApi" })
                      : messageErreur
                  }
                />
              )}
            />

            {texteSousInput}
          </>
        </SGBox>
      </SGBlock>
    </FormProvider>
  );
};

export { EffortEpargneTab };
