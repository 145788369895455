import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGContent, SGLayout } from "sg-layout";
import { SGBox, SGSpace } from "sg-space";
import { SGSpinner } from "sg-spinner";
import { SGText, SGTitle } from "sg-typo";
import {
  deletePostesDepenses,
  fetchPostesDepenses,
} from "store/budgetRetraite/actions";
import {
  resetMonObjectifEpargne,
  resetPostesDepenses,
  setObjectifRevenuBudgetErreur,
  setObjectifRevenuBudgetOK,
} from "store/budgetRetraite/budgetRetraiteSlice";
import { BudgetRetraiteState } from "store/budgetRetraite/types";
import { updatePrefs } from "store/dashboard/actions";
import { DashboardState, User } from "store/dashboard/types";
import { resetMaSimulationImpot } from "store/maSimulationImpot/maSimulationImpotSlice";
import { resetResultsEfforts } from "store/savingPath/actions";
import { State } from "store/store";
import { AccordeonQuestions } from "website/components/atoms/AccordeonQuestions/AccordeonQuestions";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { articlesAccesContenuMonObjectif } from "website/components/hooks/articles";
import { useMontantsMax } from "website/components/hooks/dashboard/useMontantsMax";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import {
  trackingEvent,
  useTrackingEvent,
} from "website/components/hooks/useTrackingEvent";
import { AccesContenus } from "website/components/molecules/AccesContenus/AccesContenus";
import { SectionAllerPlusLoin } from "website/components/molecules/NosSolutions/SectionAllerPlusLoin";
import {
  NomPageEnum,
  ordreTuilesPage,
} from "website/components/molecules/NosSolutions/utils";
import { OngletEffortEpargne } from "website/components/organisms/Dashboard/EffortEpargne/EffortEpargne";
import { ModalBlocageNavigation } from "website/components/organisms/MonObjectif/ModalBlocageNavigation";
import { TuileBesoinEpargne } from "website/components/organisms/MonObjectif/TuileBesoinEpargne";
import { TuileBudgetRetraite } from "website/components/organisms/MonObjectif/TuileBudgetRetraite";
import {
  getBoxMargin,
  getLi,
  getParagraph,
  getUl,
  getUnderline,
} from "website/utils/formatting/ValuesFormattedMessage";
import { DASHBOARD } from "website/utils/privateRoutes";

export const MonObjectifPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const saveEvent = useTrackingEvent();
  const { trackPage, trackClick } = useTracking();

  const [isReset, setIsReset] = useState(false);
  const [deblocageParcours, setDeblocageParcours] = useState(false);

  const datePremierParcoursEpargner = useSelector<State, string | undefined>(
    (state) => state.dashboard.dashboard?.firstSavingPathDate
  );
  const { dashboard }: DashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );
  const {
    objectifRevenu,
    saisieEnErreur,
    hasFetched: hasFetchedPostesDepenses,
    postesDepenses,
    postesDepensesPersistes,
  }: BudgetRetraiteState = useSelector<State, BudgetRetraiteState>(
    (state) => state.budgetRetraite
  );

  const {
    maxEpargneInput,
    objectifDefaut,
    minObjectifInput,
    maxObjectifInput,
  } = useMontantsMax();

  const [ongletCourant, setOngletCourant] = useState<OngletEffortEpargne>(OngletEffortEpargne.objectif);

  useEffect(() => {
    trackPage("mon-objectif", "accueil", "gestion-ecran");
    saveEvent(trackingEvent.OBJECTIF);
    // On relance l'appel en arrivant sur la page
    dispatch(resetMonObjectifEpargne());
    dispatch(resetMaSimulationImpot());
    dispatch(resetResultsEfforts());
    setIsReset(true);
  }, []);

  useEffect(() => {
    // save first Saving Path Date
    if (datePremierParcoursEpargner === undefined) {
      const req: User = {
        firstSavingPathDate: new Date(),
      };
      dispatch(updatePrefs(req));
    }
  }, [dispatch, datePremierParcoursEpargner]);

  useEffect(() => {
    if (!isReset) {
      if (hasFetchedPostesDepenses) {
        // On force ce qui est en mémoire avec ce qui vient de la BDD (peut être undefined)
        dispatch(resetPostesDepenses(postesDepensesPersistes));
        setIsReset(true);
      } else {
        dispatch(fetchPostesDepenses());
      }
    }
  }, [isReset, dispatch, hasFetchedPostesDepenses, postesDepensesPersistes]);

  const handleOnClickBoutonDefaut = () => {
    trackClick("clic-sur-estimer-un-besoin-par-defaut");
    if (
      objectifDefaut < minObjectifInput ||
      objectifDefaut > maxObjectifInput
    ) {
      dispatch(setObjectifRevenuBudgetErreur(objectifDefaut));
    } else {
      dispatch(setObjectifRevenuBudgetOK(objectifDefaut));
    }
    dispatch(resetPostesDepenses());
  };

  const sauvegarderSaisie = () => {
    if (objectifRevenu !== dashboard?.monthlyIncomeGoal) {
      const user: User = {
        monthlyIncomeGoal: objectifRevenu,
      };
      dispatch(updatePrefs(user));
    }
    // Pas de poste en mémoire et existant en BDD, on supprime
    if (!postesDepenses && postesDepensesPersistes) {
      dispatch(deletePostesDepenses());
    }
  };

  const handleOnClickMajEpargne = () => {
    trackClick("clic-sur-mettre-a-jour-mon-epargne");

    setDeblocageParcours(true);

    sauvegarderSaisie();
  };

  // Post sauvegarde pour bien avoir le déblocage effectif
  useEffect(() => {
    if (dashboard?.monthlyIncomeGoal === objectifRevenu && deblocageParcours) {
      history.push(DASHBOARD);
    }
  }, [history, dashboard?.monthlyIncomeGoal, objectifRevenu, deblocageParcours]);

  if (
    maxEpargneInput === undefined ||
    !isReset
  ) {
    return <SGSpinner size="lg" />;
  }

  return (
    <>
      <SGContent disableautomargin>
        <SGBox margin={{ top: "sm" }}>
          <SGTitle level={1}>
            <FormattedMessage id="monObjectif.titre" />
          </SGTitle>
        </SGBox>
      </SGContent>
      <SGLayout background="light">
        <SGContent>
          <SGGridRow justify="center">
            <SGGridCol span={12}>
              <SGTitle level={2}>
                <FormattedMessage id="monObjectif.sousTitre" />
              </SGTitle>
            </SGGridCol>

            <SGGridCol span={12} md={8}>
              <TuileBesoinEpargne
                ongletDefaut={ongletCourant}
                onTabChange={setOngletCourant}
                onClickBoutonDefaut={handleOnClickBoutonDefaut}
              />
            </SGGridCol>
            <SGGridCol span={12} md={8}>
              {ongletCourant === OngletEffortEpargne.objectif && (
                <TuileBudgetRetraite />
              )}
            </SGGridCol>
            <SGGridCol span={12} md={8} textalign="center">
              <BoutonSupervision
                type="primary"
                onClick={handleOnClickMajEpargne}
                disabled={saisieEnErreur || objectifRevenu === undefined}
              >
                <FormattedMessage id="monObjectif.bouton.maj" />
              </BoutonSupervision>
            </SGGridCol>
            <SGGridCol span={12} md={8}>
              <AccordeonQuestions
                titre="monObjectif.faq.titre"
                questionsKey={["monObjectif.estimation"]}
                transformations={{
                  p: getParagraph,
                  box: (word: string) => getBoxMargin(word, { top: "xs" }),
                  u: getUnderline,
                  ul: getUl,
                  li: getLi,
                }}
              />
            </SGGridCol>
            <SGGridCol span={12}>
              <SGBox margin={{ top: "xl", bottom: "xl" }}>
                <SGSpace direction="vertical" size="xl">
                  <SectionAllerPlusLoin
                    key="accompagner"
                    nom={NomPageEnum.MON_OBJECTIF}
                    ordreTuilesPage={ordreTuilesPage[NomPageEnum.MON_OBJECTIF]}
                  />

                  <AccesContenus
                    key="contenus"
                    idMedias={articlesAccesContenuMonObjectif}
                    nbSlides={3}
                  />

                  <SGText key="disclaimer">
                    <FormattedMessage id="monObjectif.disclaimer" />
                  </SGText>
                </SGSpace>
              </SGBox>
            </SGGridCol>
          </SGGridRow>
        </SGContent>
        {isReset && (
          <ModalBlocageNavigation
            conditionBlocage={
              objectifRevenu !== undefined &&
              objectifRevenu !== dashboard?.monthlyIncomeGoal &&
              !saisieEnErreur
            }
            conditionDeblocage={postesDepenses !== undefined || saisieEnErreur}
            deblocageManuel={deblocageParcours}
            onValiderModification={sauvegarderSaisie}
          />
        )}
      </SGLayout>
    </>
  );
};
