import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BudgetRetraiteState, PostesDepensesDTO } from "./types";

const initialState: BudgetRetraiteState = {
  saisieEnErreur: false,
  hasFetched: false,
};

export const budgetRetraiteSlice = createSlice({
  name: "budgetRetraite",
  initialState,
  reducers: {
    resetMonObjectifEpargne: (currentState: BudgetRetraiteState) => ({
      ...currentState,
      typeDepart: undefined,
      epargneMaximale: undefined,

      objectifRevenu: undefined,
      montantEpargne: undefined,
      revenuAFinancer: undefined,
    }),
    setEpargneMaximale: (
      currentState: BudgetRetraiteState,
      action: PayloadAction<Partial<BudgetRetraiteState>>
    ): BudgetRetraiteState => ({
      ...currentState,
      typeDepart: action.payload.typeDepart,
      epargneMaximale: action.payload.epargneMaximale,
    }),
    setObjectifRevenuBudgetOK: (
      currentState: BudgetRetraiteState,
      action: PayloadAction<number | undefined>
    ): BudgetRetraiteState => ({
      ...currentState,
      objectifRevenu: action.payload,
      saisieEnErreur: false,
    }),
    setObjectifRevenuBudgetErreur: (
      currentState: BudgetRetraiteState,
      action: PayloadAction<number | undefined>
    ): BudgetRetraiteState => ({
      ...currentState,
      objectifRevenu: action.payload,
      saisieEnErreur: true,
    }),
    setMontantEpargneBudgetOK: (
      currentState: BudgetRetraiteState,
      action: PayloadAction<number | undefined>
    ): BudgetRetraiteState => ({
      ...currentState,
      montantEpargne: action.payload,
      saisieEnErreur: false,
    }),
    setMontantEpargneBudgetErreur: (
      currentState: BudgetRetraiteState,
      action: PayloadAction<number | undefined>
    ): BudgetRetraiteState => ({
      ...currentState,
      montantEpargne: action.payload,
      saisieEnErreur: true,
    }),
    postesDepensesFetched: (
      currentState: BudgetRetraiteState,
      action: PayloadAction<PostesDepensesDTO | undefined>
    ): BudgetRetraiteState => ({
      ...currentState,
      hasFetched: true,
      postesDepenses: action.payload,
      postesDepensesPersistes: action.payload,
    }),
    /**
     * Force l'état des postes de dépenses en mémoire (peut être undefined)
     */
    resetPostesDepenses: (
      currentState: BudgetRetraiteState,
      action: PayloadAction<PostesDepensesDTO | undefined>
    ): BudgetRetraiteState => ({
      ...currentState,
      postesDepenses: action.payload,
    }),
  },
});

export const {
  resetMonObjectifEpargne,
  setEpargneMaximale,
  setObjectifRevenuBudgetOK,
  setObjectifRevenuBudgetErreur,
  setMontantEpargneBudgetOK,
  setMontantEpargneBudgetErreur,
  postesDepensesFetched,
  resetPostesDepenses,
} = budgetRetraiteSlice.actions;

export default budgetRetraiteSlice.reducer;
