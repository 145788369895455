import React, { FunctionComponent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { NumericInput } from "../NumericInput/NumericInput";

interface RoundedSalaryProps {
  label?: string;
  labelSize?: "s" | "m" | "l";
  placeholder?: string;
  salary: number | undefined;
  disabled?: boolean;
  setSalary?: (val?: number) => void;
  maxValueInput?: number;
  minValueInput?: number;
  setHasChangedValue?: (val: boolean) => void;
  disableautomargin?: boolean;
  minMessage?: string;
  required?: boolean;
  onResetInput?: () => void;
}

/**
 * Ce composant permet l'afficher d'un champ de saisie avec un arrondi du montant
 */
const RoundedSalary: FunctionComponent<RoundedSalaryProps> = (
  props: React.PropsWithChildren<RoundedSalaryProps>
) => {
  const {
    label,
    labelSize,
    placeholder,
    salary,
    disabled = false,
    setSalary,
    maxValueInput,
    setHasChangedValue,
    disableautomargin = false,
    minValueInput,
    minMessage,
    required = false,
    onResetInput
  } = props;
  // Variable interne pour éviter les effets de bord
  const [salaryInput, setSalaryInput] = useState(salary);

  const [isResetInput, setIsResetInput] = useState(false);

  const methods = useForm({
    criteriaMode: "all",
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  // Initialisation de la variable interne, si besoin
  useEffect(() => {
    if (salary !== undefined && salaryInput === undefined) {
      setSalaryInput(salary);
    }
  }, [salary]);

  // Arrondit le montant lorsque l'on sort du champ de saisie
  const onBlur = () => {
    if (salaryInput && setSalary) {
      if (maxValueInput && salaryInput > maxValueInput) {
        setSalaryInput(maxValueInput);
        setSalary(maxValueInput);
      } else {
        setSalaryInput(roundDown10(salaryInput));
        setSalary(roundDown10(salaryInput));
      }
      if (setHasChangedValue !== undefined) {
        setHasChangedValue(true);
      }
    }
    if (onResetInput && isResetInput && setSalary) {
      setSalary(undefined);
      onResetInput();
    }
  };

  const onSalaryChangeInput = (value?: number) => {
    if (setSalary !== undefined && typeof value === "number") {
      setSalaryInput(value);
      setSalary(
        maxValueInput && value > maxValueInput
          ? maxValueInput
          : roundDown10(value)
      );
      setIsResetInput(false);
    }

    if (typeof value === "string") {
      setSalaryInput(undefined);
      setIsResetInput(true);
    }
  };

  return (
    <FormProvider {...methods}>
      <NumericInput
        label={label}
        labelSize={labelSize}
        defaultValue={salaryInput}
        disabled={disabled}
        name="salaryMonthDS"
        cypressName="salary-month-ds"
        min={minValueInput}
        minValueInput={0}
        maxValueInput={maxValueInput}
        placeholder={placeholder ?? "--"}
        onValueChange={(value) => {
          onSalaryChangeInput(value);
        }}
        overrideDefaultValue
        disableautomargin={disableautomargin}
        onBlurInput={onBlur}
        minMessage={minMessage}
        required={required}
      />
    </FormProvider>
  );
};

export { RoundedSalary };
