import { PropsWithChildren, ReactNode } from "react";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGBox } from "sg-space";
import { SGTile } from "sg-tile";
import { SGText, SGTitle } from "sg-typo";

interface TuileCliquableLienPersonaliseProps {
  icone?: JSX.Element;
  /**
   * Pour les pictos Avenir stroked : s, m, l
   * Pour les pictos Avenir styled : s, m
   */
  iconeTaille?: string;
  titre?: string | ReactNode;
  titreTaille?: number;
  titleLevel?: number;
  texte?: string | ReactNode;
  texteTaille?: "m" | "s";
  lien: ReactNode;
  accessible?: boolean;
  disableautomargin?: boolean;
  width?: "auto" | null;
  alignchildren?: "center";
}

export const TuileCliquableLienPersonalise = ({
  icone,
  iconeTaille = "l",
  titre,
  titreTaille,
  texte,
  texteTaille = "s",
  lien,
  accessible = true,
  disableautomargin = false,
  width,
  alignchildren,
  children,
  titleLevel = 3,
}: PropsWithChildren<TuileCliquableLienPersonaliseProps>) => {
  const titleVisuallevel = titreTaille ?? titleLevel + 2;

  return (
    <SGTile
      clickable={accessible ? "full" : accessible}
      disableautomargin={disableautomargin}
      width={width}
    >
      <SGBox
        alignchildren={alignchildren ? [alignchildren] : undefined}
      >
        <SGGridRow align="middle">
          {icone && (
            <SGGridCol flex="0 0 0%" textalign="center">
              <SGIcon component={icone} size={iconeTaille} />
            </SGGridCol>
          )}
          <SGGridCol flex="1 0 0%">
            {titre && (
              <SGTitle level={titleLevel} visuallevel={titleVisuallevel}>
                {titre}
              </SGTitle>
            )}
            {texte && <SGText size={texteTaille}>{texte}</SGText>}
            {children}
          </SGGridCol>
          <SGGridCol flex="0 0 0%">
            {lien}
          </SGGridCol>
        </SGGridRow>
      </SGBox>
    </SGTile>
  );
};
